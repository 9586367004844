import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useGlobal } from "reactn";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import Transaction from "./Transaction";
import RequestPayout from "./RequestPayout";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
  }
  @media screen and (max-width: 400px) {
    padding: 20px;
  }
`;

const Payout = styled.div`
  border-radius: 4px;
  padding: 20px;
  background: linear-gradient(135deg, hsl(184, 93%, 37%), hsl(211, 95%, 51%));
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const SmallTitle = styled.h4`
  font-size: 12px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
`;

const Balance = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: -6px;
  color: white;
  @media screen and (max-width: 900px) {
    font-size: 24px;
  }
`;

const RequestButton = styled.div`
  background: rgba(255, 255, 255, 0.2);
  color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  padding: 4px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background 0.2s;
  @media screen and (max-width: 900px) {
    padding: 3px 14px;
  }
  :hover {
    background: rgba(255, 255, 255, 0.25);
  }
`;

const LeftSide = styled.div`
  flex: 1;
  margin-right: 40px;
  @media screen and (max-width: 900px) {
    margin-right: 0;
    margin-top: 20px;
  }
`;

const RightSide = styled.div`
  flex: 1;
  max-width: 400px;
  @media screen and (max-width: 900px) {
    flex: inherit;
    max-width: 100%;
  }
`;

const Title = styled.h2`
  font-weight: 700;
  color: rgba(255, 255, 255, 0.9);
  font-size: 24px;
  margin-top: 0;
  @media screen and (max-width: 900px) {
    font-size: 20px;
  }
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
`;

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [requestOpen, setRequestOpen] = useState(false);
  const [authState] = useGlobal("authState");
  let preReturn;
  const skipPaymentMethod =
    authState &&
    authState.paymentProfile &&
    localStorage.getItem("skip_add_payment_method") &&
    Array.isArray(
      JSON.parse(localStorage.getItem("skip_add_payment_method"))
    ) &&
    JSON.parse(localStorage.getItem("skip_add_payment_method")).includes(
      authState.paymentProfile.id
    );
  if (
    authState &&
    !authState.loading &&
    authState.authenticated &&
    authState.paymentProfile &&
    authState.paymentProfile.setupStatus !== "done"
  ) {
    if (authState.paymentProfile.setupStatus === "needs_all_details")
      preReturn = <Redirect to="/addTaxForm" />;
    if (authState.paymentProfile.setupStatus === "needs_electronic_tax_request")
      preReturn = <Redirect to="/setElectronicTaxDelivery" />;
    if (
      authState.paymentProfile.setupStatus === "needs_payment_method" &&
      !skipPaymentMethod
    )
      preReturn = <Redirect to="/addPaymentMethod" />;
  } else if (
    authState &&
    !authState.loading &&
    authState.authenticated &&
    authState.paymentProfiles.length === 0
  )
    preReturn = <Redirect to="/settings" />;

  // Get dashboard info
  async function getDashboard() {
    const response = await axios.post(
      apiRoot,
      {
        query: `
          query getDashboardInfo($id: Int!) {
            paymentProfile(id: $id) {
              balance
              us_balance
              withholdingAmount
              withholdingInfo {
                country
                royaltyRate
              }
              taxApproved
              transactions {
                __typename
                ...on Deposit {
                  id
                  amount
                  source
                  comment
                  created_at
                }
                ...on Withdrawal {
                  id
                  amount
                  amount_paid
                  tax_withheld
                  paid
                  created_at
                  automatic
                  payment_method {
                    type
                    paypalEmail
                    bankName
                    bankAccountType
                    swift
                    wireAccountNumberType
                    accountNumberLast4
                    addressLine1
                    addressLine2
                    addressCity
                    addressState
                    addressZip
                  }
                }
              }
            }
          }`,
        variables: { id: profileId },
      },
      { withCredentials: true }
    );
    if (response?.data?.data?.paymentProfile) {
      setData(response.data.data.paymentProfile);
    }
  }

  const profileId = authState?.paymentProfile?.id;
  useEffect(() => {
    if (preReturn) return;
    if (!authState?.paymentProfile) return;
    getDashboard();
    // eslint-disable-next-line
  }, [profileId, Boolean(preReturn)]);

  if (preReturn) return preReturn;

  return (
    <MainComponent>
      {requestOpen && (
        <RequestPayout
          id={profileId}
          paymentProfile={data}
          refresh={getDashboard}
          close={() => setRequestOpen(false)}
        />
      )}
      <LeftSide>
        <Title>Transaction History</Title>
        {data && data.transactions && data.transactions.length === 0 && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="64"
              height="64"
            >
              <path
                fill="rgba(255,255,255,0.2)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
              />
              <path
                fill="rgba(255,255,255,0.9)"
                d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
              />
            </svg>
            <Title>You have no transactions!</Title>
            <Text>
              You'll see any changes to your Music Inc. payment balance here
              when they are available.
            </Text>
          </div>
        )}
        {data?.transactions?.map((transaction) => (
          <Transaction
            key={transaction.__typename + transaction.id}
            transaction={transaction}
          />
        ))}
        {!data && <SmallTitle>Loading...</SmallTitle>}
      </LeftSide>
      <RightSide>
        <Payout>
          <div>
            <SmallTitle>YOUR BALANCE</SmallTitle>
            <Balance>
              {data && data.hasOwnProperty("balance")
                ? formatter.format(data?.balance)
                : "Loading..."}
            </Balance>
          </div>
          {data && (
            <RequestButton onClick={() => setRequestOpen(true)}>
              Request Payout
            </RequestButton>
          )}
        </Payout>
      </RightSide>
    </MainComponent>
  );
};

export default Dashboard;
