const openpgp = require("openpgp");

const key = `
-----BEGIN PGP PUBLIC KEY BLOCK-----

mDMEX8Pr4RYJKwYBBAHaRw8BAQdA5WIgCVw18kBQStMMIH3z/mX628TisI7yZGSS
73QmOSS0HEtpYW4gTW9yZXR6IDxraWFuQG11c2ljLmNlbz6IkAQTFggAOBYhBEhC
SKcla2d7Uly5ntEDucO64HeNBQJfw+vhAhsDBQsJCAcCBhUKCQgLAgQWAgMBAh4B
AheAAAoJENEDucO64HeN/AkA/RWzNImN8bhHur0AjyPjv6g2NBgb+NXmlIp+dRbo
vHenAP9/ZJE+cr3pQjh+W+SpSfLu/BwccY4foM2xDuw4O20VArg4BF/D6+ESCisG
AQQBl1UBBQEBB0AOZwYBx6w1pB8EIeOrz8O8ddHCCm7w79rZTWDmy9MOFAMBCAeI
eAQYFggAIBYhBEhCSKcla2d7Uly5ntEDucO64HeNBQJfw+vhAhsMAAoJENEDucO6
4HeNtKsBANNszo9BdxdlDLdegnDcuFNgo45xuRG2CgNBHlAJaY4ZAQDySrbacdY4
pgnNS67BILrjOIFm7PD++1u1rJCgnI31Aw==
=hqY5
-----END PGP PUBLIC KEY BLOCK-----
`;

const encrypt = async (obj) => {
  const string = JSON.stringify(obj);
  const publicKey = await openpgp.key.readArmored(key);
  const encrypted = await openpgp.encrypt({
    message: openpgp.message.fromText(string),
    publicKeys: publicKey.keys,
  });
  return encrypted.data;
};

export default encrypt;
