import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import apiRoot from "../../apiRoot";

const Container = styled.div`
  max-width: 400px;
`;

const Divider = styled.div`
  width: 90%;
  margin: 30px auto;
  height: 1px;
  background: rgba(255, 255, 255, 0.15);
`;

const InputGroup = styled.div`
  h3 {
    color: white;
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const Label = styled.label`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const Text = styled.p`
  margin: 0;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const VertDiv = styled.div`
  margin-top: 20px;
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  margin-bottom: 10px;
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
`;

const Button = styled.button`
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  color: hsl(221, 92%, 75%);
  font-weight: 600;
  display: block;
  text-align: center;
  padding: 8px;
  width: 100%;
  border: none;
  border-radius: 2px;
  transition: background 0.2s;
  outline: none;
  margin-top: 10px;
  :hover {
    background: rgba(255, 255, 255, 0.25);
  }
  &:disabled {
    background: rgba(255, 255, 255, 0.1);
    cursor: default;
  }
`;

const ButtonLink = styled(Button.withComponent(Link))`
  font-size: 14px;
  text-decoration: none;
  box-sizing: border-box;
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    margin-right: 10px;
  }
`;

const PaymentMethodContainer = styled.div`
  display: flex;
  padding: 10px 15px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 10px;
`;

const Icon = styled.div`
  svg {
    width: 40px;
    height: 40px;
  }
  margin-right: 15px;
`;

const MethodText = styled.div``;

const MethodType = styled.h5`
  margin: 0 0 5px 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
`;

const SubDetail = styled.span`
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
`;

const AutoPayout = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
  margin-top: 5px;
`;

const RemoveContainer = styled.div`
  margin-top: 5px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
`;

const RemoveButton = styled.span`
  display: inline-block;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
`;

const RemoveButton2 = styled.span`
  display: inline-block;
  cursor: pointer;
  padding: 3px 8px;
  border-radius: 2px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  background: rgba(255, 255, 255, 0.08);
  margin: 0 15px;
  transition: background 0.2s;
  :hover {
    background: rgba(255, 255, 255, 0.12);
  }
`;

const taxForms = { W9: "W-9", W8BENE: "W-8BEN-E", W8BEN: "W-8BEN" };

const accountTypes = {
  businessSavings: "business savings",
  businessChecking: "business checking",
  personalSavings: "personal savings",
  personalChecking: "personal checking",
};

const PaymentMethod = (props) => {
  let [removing, setRemoving] = useState(false);
  const remove = async () => {
    await axios.post(
      apiRoot,
      {
        query: `mutation changeName($id: Int!, $pmid: Int!) {
        paymentProfile(id: $id) {
          removePaymentMethod(id: $pmid)
        }
      }`,
        variables: { id: Number(props.id), pmid: Number(props.method.id) },
      },
      { withCredentials: true }
    );
    props.refresh();
  };

  const i = props.method;
  if (i.type === "international_wire") {
    return (
      <PaymentMethodContainer>
        <Icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 100 100"
          >
            <path
              fill="rgba(255,255,255,0.7)"
              d="M90.1,80.1v-8.4c0-0.8-0.6-1.4-1.4-1.4h-7.4v-33h7.4c0.8,0,1.4-0.6,1.4-1.4V24.4c0-0.5-0.3-1-0.8-1.3L50.5,6.2    c-0.3-0.2-0.7-0.2-1.1,0L10.7,23.1c-0.5,0.2-0.8,0.7-0.8,1.3V36c0,0.8,0.6,1.4,1.4,1.4h8v33h-8c-0.8,0-1.4,0.6-1.4,1.4v8.4h-6    c-0.8,0-1.4,0.6-1.4,1.4v11c0,0.8,0.6,1.4,1.4,1.4h92.3c0.8,0,1.4-0.6,1.4-1.4v-11c0-0.8-0.6-1.4-1.4-1.4H90.1z M13.9,33.4v-7.3    L50,10.4l36.1,15.8v7.3H13.9z M77.3,37.4v33h-7.6v-33H77.3z M65.7,37.4v33h-7.6v-33H65.7z M54.1,37.4v33h-7.6v-33H54.1z     M42.5,37.4v33h-7.6v-33H42.5z M30.8,37.4v33h-7.6v-33H30.8z M13.9,74.4h72.2v5.7H13.9V74.4z M93.5,89.9h-87v-5.7h87V89.9z"
            />
          </svg>
        </Icon>
        <MethodText>
          <MethodType>International Wire Transfer</MethodType>
          <SubDetail>
            SWIFT code {i.swift},{" "}
            {i.wireAccountNumberType === "IBAN" ? "IBAN" : "account number"}{" "}
            ending in {i.accountNumberLast4}
          </SubDetail>
          {props.isDefault && (
            <AutoPayout>
              Auto-payout enabled{" "}
              <RemoveButton
                onClick={() => props.setAutoPayoutMethod(i.id, true)}
              >
                (disable)
              </RemoveButton>
            </AutoPayout>
          )}
          {!props.isDefault && (
            <AutoPayout>
              <RemoveButton
                onClick={() => props.setAutoPayoutMethod(i.id, false)}
              >
                Enable auto-payout
              </RemoveButton>
            </AutoPayout>
          )}
          {removing ? (
            <RemoveContainer>
              are you sure?
              <RemoveButton2 onClick={remove}>remove</RemoveButton2>
              <RemoveButton onClick={() => setRemoving(false)}>
                cancel
              </RemoveButton>
            </RemoveContainer>
          ) : (
            <RemoveContainer>
              <RemoveButton onClick={() => setRemoving(true)}>
                remove
              </RemoveButton>
            </RemoveContainer>
          )}
        </MethodText>
      </PaymentMethodContainer>
    );
  }
  if (i.type === "us_bank") {
    return (
      <PaymentMethodContainer>
        <Icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 100 100"
          >
            <path
              fill="rgba(255,255,255,0.7)"
              d="M90.1,80.1v-8.4c0-0.8-0.6-1.4-1.4-1.4h-7.4v-33h7.4c0.8,0,1.4-0.6,1.4-1.4V24.4c0-0.5-0.3-1-0.8-1.3L50.5,6.2    c-0.3-0.2-0.7-0.2-1.1,0L10.7,23.1c-0.5,0.2-0.8,0.7-0.8,1.3V36c0,0.8,0.6,1.4,1.4,1.4h8v33h-8c-0.8,0-1.4,0.6-1.4,1.4v8.4h-6    c-0.8,0-1.4,0.6-1.4,1.4v11c0,0.8,0.6,1.4,1.4,1.4h92.3c0.8,0,1.4-0.6,1.4-1.4v-11c0-0.8-0.6-1.4-1.4-1.4H90.1z M13.9,33.4v-7.3    L50,10.4l36.1,15.8v7.3H13.9z M77.3,37.4v33h-7.6v-33H77.3z M65.7,37.4v33h-7.6v-33H65.7z M54.1,37.4v33h-7.6v-33H54.1z     M42.5,37.4v33h-7.6v-33H42.5z M30.8,37.4v33h-7.6v-33H30.8z M13.9,74.4h72.2v5.7H13.9V74.4z M93.5,89.9h-87v-5.7h87V89.9z"
            />
          </svg>
        </Icon>
        <MethodText>
          <MethodType>ACH Bank Transfer</MethodType>
          <SubDetail>
            {accountTypes[i.bankAccountType]} account at {i.bankName} ending in{" "}
            {i.accountNumberLast4}
          </SubDetail>
          {props.isDefault && (
            <AutoPayout>
              Auto-payout enabled{" "}
              <RemoveButton
                onClick={() => props.setAutoPayoutMethod(i.id, true)}
              >
                (disable)
              </RemoveButton>
            </AutoPayout>
          )}
          {!props.isDefault && (
            <AutoPayout>
              <RemoveButton
                onClick={() => props.setAutoPayoutMethod(i.id, false)}
              >
                Enable auto-payout
              </RemoveButton>
            </AutoPayout>
          )}
          {removing ? (
            <RemoveContainer>
              are you sure?
              <RemoveButton2 onClick={remove}>remove</RemoveButton2>
              <RemoveButton onClick={() => setRemoving(false)}>
                cancel
              </RemoveButton>
            </RemoveContainer>
          ) : (
            <RemoveContainer>
              <RemoveButton onClick={() => setRemoving(true)}>
                remove
              </RemoveButton>
            </RemoveContainer>
          )}
        </MethodText>
      </PaymentMethodContainer>
    );
  }
  if (i.type === "paypal") {
    return (
      <PaymentMethodContainer>
        <Icon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <path
              fill="rgba(255,255,255,0.4)"
              d="M47.77,63.24a.5.5,0,0,1,.49-.41H60A18.62,18.62,0,0,0,78.23,47.89l2.12-10.58A11.26,11.26,0,0,0,70.7,23.93c0,.08,0,.16,0,.25L68.55,34.76A19.64,19.64,0,0,1,49.34,50.5H38L32.75,76.76v0L30.2,89.5H42.52Z"
            />
            <path
              fill="rgba(255,255,255,0.7)"
              d="M31.85,76.17,37.11,49.9a.49.49,0,0,1,.49-.4H49.34A18.64,18.64,0,0,0,67.57,34.56L69.68,24s0-.08,0-.12,0-.33.07-.5a4.29,4.29,0,0,0,.07-.5A11.26,11.26,0,0,0,58.63,10.5h-26L19.54,76.17Z"
            />
          </svg>
        </Icon>
        <MethodText>
          <MethodType>PayPal</MethodType>
          <SubDetail>{i.paypalEmail}</SubDetail>
          {props.isDefault && (
            <AutoPayout>
              Auto-payout enabled{" "}
              <RemoveButton
                onClick={() => props.setAutoPayoutMethod(i.id, true)}
              >
                (disable)
              </RemoveButton>
            </AutoPayout>
          )}
          {!props.isDefault && (
            <AutoPayout>
              <RemoveButton
                onClick={() => props.setAutoPayoutMethod(i.id, false)}
              >
                Enable auto-payout
              </RemoveButton>
            </AutoPayout>
          )}
          {removing ? (
            <RemoveContainer>
              are you sure?
              <RemoveButton2 onClick={remove}>remove</RemoveButton2>
              <RemoveButton onClick={() => setRemoving(false)}>
                cancel
              </RemoveButton>
            </RemoveContainer>
          ) : (
            <RemoveContainer>
              <RemoveButton onClick={() => setRemoving(true)}>
                remove
              </RemoveButton>
            </RemoveContainer>
          )}
        </MethodText>
      </PaymentMethodContainer>
    );
  }
  if (i.type === "check") {
    return (
      <PaymentMethodContainer>
        <Icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 64 64"
          >
            <path
              fill="rgba(255,255,255,0.7)"
              d="M55,55.3H40c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h15c0.5,0,0.9,0.4,0.9,0.9C55.9,54.9,55.5,55.3,55,55.3z"
            />
            <path
              fill="rgba(255,255,255,0.7)"
              d="M24,40.4H9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h15c0.5,0,0.9,0.4,0.9,0.9C24.9,40,24.5,40.4,24,40.4z"
            />
            <path
              fill="rgba(255,255,255,0.7)"
              d="M37.6,45.4H9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h28.4L37.6,45.4z"
            />
            <path
              fill="rgba(255,255,255,0.7)"
              d="M55.9,44.5c0,0.5-0.4,0.9-0.9,0.9H43.6l0.9-0.8c0.3-0.3,0.5-0.6,0.7-1l0,0H55C55.5,43.6,55.9,44,55.9,44.5z"
            />
            <path
              fill="rgba(255,255,255,0.7)"
              d="M55.9,49.5c0,0.5-0.4,0.9-0.9,0.9H9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9H55C55.5,48.5,55.9,49,55.9,49.5z"
            />
            <path
              fill="rgba(255,255,255,0.7)"
              d="M60.1,6.8l-0.3-0.1C60,6,59.6,5.2,58.9,4.9l-0.3-0.1l0.8-2c0.2-0.4,0-0.8-0.4-1l-1.6-0.7c-0.4-0.2-0.8,0-1,0.4l-0.8,2   l-0.3-0.1c-0.8-0.3-1.7,0-2.1,0.8l-7.6,18.2c-0.3,0.8-0.2,1.6,0.1,2.3l-6.8,16.2C39,41.2,39,41.4,39,41.5l0.2,5.2   c0,0.2,0.2,0.3,0.3,0.1l3.9-3.5c0.1-0.1,0.2-0.2,0.3-0.4l6.8-16.2c0.8-0.3,1.4-0.8,1.7-1.6L59.3,8l0.2,0.1c1.2,0.5,1.8,1.9,1.3,3.1   l-6.6,15.8c-0.1,0.4,0,0.8,0.4,0.9c0.4,0.1,0.8,0,0.9-0.4l6.6-15.8C62.9,9.8,62,7.6,60.1,6.8z"
            />
            <path
              fill="rgba(255,255,255,0.7)"
              d="M61,31.1H50.4l-1.2,3h10.4v25.8H4.6V34h35.7l1.2-3H3c-0.8,0-1.5,0.7-1.5,1.5v28.8c0,0.8,0.7,1.5,1.5,1.5h58   c0.8,0,1.5-0.7,1.5-1.5V32.6C62.5,31.7,61.8,31.1,61,31.1z"
            />
          </svg>
        </Icon>
        <MethodText>
          <MethodType>Paper Check</MethodType>
          <SubDetail>Payable to {i.payTo}</SubDetail>
          <SubDetail>
            Mailed to {i.addressLine1}, {i.addressCity}, {i.addressState}{" "}
            {i.addressZip}
          </SubDetail>
          {props.isDefault && (
            <AutoPayout>
              Auto-payout enabled{" "}
              <RemoveButton
                onClick={() => props.setAutoPayoutMethod(i.id, true)}
              >
                (disable)
              </RemoveButton>
            </AutoPayout>
          )}
          {!props.isDefault && (
            <AutoPayout>
              <RemoveButton
                onClick={() => props.setAutoPayoutMethod(i.id, false)}
              >
                Enable auto-payout
              </RemoveButton>
            </AutoPayout>
          )}
          {removing ? (
            <RemoveContainer>
              are you sure?
              <RemoveButton2 onClick={remove}>remove</RemoveButton2>
              <RemoveButton onClick={() => setRemoving(false)}>
                cancel
              </RemoveButton>
            </RemoveContainer>
          ) : (
            <RemoveContainer>
              <RemoveButton onClick={() => setRemoving(true)}>
                remove
              </RemoveButton>
            </RemoveContainer>
          )}
        </MethodText>
      </PaymentMethodContainer>
    );
  }
};

const ProfileSettings = (props) => {
  let [authState, setAuthState] = useGlobal("authState");
  let profile = authState?.paymentProfiles?.filter(
    (i) => i.id === Number(props.id)
  )[0];
  let [loaded, setLoaded] = useState(profile ? true : false);
  let [name, setName] = useState(profile?.name);
  let [data, setData] = useState(null);
  let [working, setWorking] = useState(false);
  let [error, setError] = useState(null);
  if (profile && !loaded) {
    setName(profile.name);
    setLoaded(true);
  }

  async function getData() {
    const response = await axios.post(
      apiRoot,
      {
        query: `
          query getDashboardInfo($id: Int!) {
            paymentProfile(id: $id) {
              taxAddressLine1
              taxAddressCity
              taxAddressState
              taxAddressCountry
              taxAddressZip
              electronicFormDelivery
              withholdingInfo {
                country
                royaltyRate
              }
              autoPayoutMethod {
                id
              }
              paymentMethods {
                id
                type
                paypalEmail
                bankName
                routingNumber
                swift
                wireAccountNumberType
                accountNumberLast4
                bankAccountType
                payTo
                addressLine1
                addressLine2
                addressCity
                addressState
                addressZip
              }
            }
          }`,
        variables: { id: Number(props.id) },
      },
      { withCredentials: true }
    );
    if (response?.data?.data?.paymentProfile) {
      setData(response.data.data.paymentProfile);
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [props.id]);

  async function mutate(name) {
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation changeName($id: Int!, $name: Name!) {
        paymentProfile(id: $id) {
          renamePaymentAccount(name: $name)
        }
      }`,
        variables: { id: Number(props.id), name },
      },
      { withCredentials: true }
    );
    if (response.data.errors) return response.data.errors[0].message;
    return "";
  }

  async function saveProfileChanges() {
    if (!name) return setError("Name required!");
    if (name.length > 50)
      return setError("Name must be 50 characters or less!");
    setWorking(true);
    setError(await mutate(name));
    setWorking(false);
    setAuthState({
      ...authState,
      paymentProfile:
        authState.paymentProfile.id === Number(props.id)
          ? { ...authState.paymentProfile, name }
          : authState.paymentProfile,
      paymentProfiles: authState.paymentProfiles.map((i) =>
        i.id === Number(props.id) ? { ...i, name } : i
      ),
    });
  }

  async function setAutoPayoutMethod(id, disable) {
    await axios.post(
      apiRoot,
      {
        query: `mutation setAutoPayoutMethod($id: Int!, $id2: Int!, $disable: Boolean!) {
        paymentProfile(id: $id) {
          setAutoPayoutMethod(paymentMethod: $id2, disable: $disable)
        }
      }`,
        variables: { id: Number(props.id), id2: id, disable },
      },
      { withCredentials: true }
    );
    setData({ ...data, autoPayoutMethod: disable ? null : { id } });
  }

  if (!authState || !profile || !data)
    return (
      <Container>
        <Label>Loading...</Label>
      </Container>
    );

  return (
    <Container>
      <InputGroup>
        <h3>Basic Details</h3>
        <Label>Payment profile name</Label>
        <Input value={name} onChange={(e) => setName(e.target.value)}></Input>
        {error && (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="hsl(5, 100%, 70%)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
              />
            </svg>
            {error}
          </ErrorMsg>
        )}
        <Button disabled={working} onClick={saveProfileChanges}>
          Save changes
        </Button>
      </InputGroup>
      <Divider />
      <InputGroup>
        <h3>Tax Form</h3>
        <Text>You submitted a Form {taxForms[profile.taxFormType]}.</Text>
        {data.withholdingInfo && <VertDiv />}
        {data.withholdingInfo && data.withholdingInfo.country && (
          <Text>
            We're required to withhold royalties from United States sources at a{" "}
            {data.withholdingInfo.royaltyRate * 100}% rate based on the tax
            treaty between the United States and {data.withholdingInfo.country}.
          </Text>
        )}
        {data.withholdingInfo && !data.withholdingInfo.country && (
          <Text>
            We're required to withhold royalties from United States sources at a{" "}
            {data.withholdingInfo.royaltyRate * 100}% rate because no tax treaty
            exists between the United States and your country of residence.
          </Text>
        )}
        <ButtonLink to="/addTaxForm">Submit new tax form</ButtonLink>
      </InputGroup>
      <Divider />
      <InputGroup>
        <h3>Tax Delivery</h3>
        {!data.electronicFormDelivery && (
          <Text>
            Your end of year tax form will be sent by mail to the address you
            supplied on your Form {taxForms[profile.taxFormType]}:
          </Text>
        )}
        {!data.electronicFormDelivery && (
          <Text>
            <br />
            {data.taxAddressLine1}
            <br />
            {data.taxAddressLine2}
            {data.taxAddressLine2 && <br />}
            {data.taxAddressCity},{" "}
            {data.taxAddressState && `${data.taxAddressState}, `}
            {data.taxAddressCountry} {data.taxAddressZip}
          </Text>
        )}
        {data.electronicFormDelivery && (
          <Text>
            You've chosen to receive your end of year tax form electronically.
            We'll email you when it's available to view.
          </Text>
        )}
        <ButtonLink to="/setElectronicTaxDelivery">
          Change delivery setting
        </ButtonLink>
      </InputGroup>
      <Divider />
      <InputGroup>
        <h3>Payment Methods</h3>
        <Text>
          You can enable auto-payout on one of your payment methods, and we'll
          automatically send payouts when you hit the balance threshold.
        </Text>
        <div style={{ marginTop: "15px" }} />
        {data.paymentMethods.map((i) => (
          <PaymentMethod
            key={i.id}
            method={i}
            id={props.id}
            refresh={getData}
            isDefault={
              data.autoPayoutMethod && data.autoPayoutMethod.id === i.id
            }
            setAutoPayoutMethod={setAutoPayoutMethod}
          />
        ))}
        <ButtonLink to="/addPaymentMethod">Add new payment method</ButtonLink>
      </InputGroup>
    </Container>
  );
};

export default ProfileSettings;
