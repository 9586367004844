import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import InputMask from "react-input-mask";
import axios from "axios";
import apiRoot from "../../apiRoot";
import ItemSelect from "../../components/ItemSelect";
import ButtonSelect from "../../components/ButtonSelect";
import encrypt from "./encrypt";
import getAuthState from "../../getAuthState";

const FormContainer = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 30px;
`;

const FormTitle = styled.h3`
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.9);
  margin-top: 0;
  font-size: 22px;
  margin-bottom: 5px;
  span {
    margin-left: 20px;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 600;
    small {
      font-size: 12px;
      font-weight: 400;
    }
  }
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const ExternalLink = styled.a`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin-bottom: 15px;
  display: inline-block;
`;

const InputLabel = styled.label`
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  margin-top: 10px;
  strong {
    margin-right: 5px;
    color: rgba(255, 255, 255, 0.8);
  }
`;

const Input = styled.input`
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: border-color 0.2s;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  padding: 4px 10px;
  width: 100%;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  background: transparent;
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.5);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5);
  }
  :focus {
    border-color: rgba(255, 255, 255, 0.2);
  }
`;

const Spacer = styled.div`
  margin-top: 5px;
`;

const HorizontalSpacer = styled.div`
  margin-right: 15px;
`;

const PartLabel = styled.h3`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  margin-top: 30px;
  strong {
    color: white;
    background: rgba(255, 255, 255, 0.1);
    padding: 5px 10px;
    border-radius: 4px;
    margin-right: 10px;
    letter-spacing: 0.5px;
  }
`;

const Certification = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  br {
    line-height: 3;
  }
`;

const Flex = styled.div`
  display: flex;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const InputGroup = styled.div`
  flex: 1;
`;

const Button = styled.button`
  padding: 6px 25px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  line-height: 1;
  :hover {
    filter: brightness(110%);
  }
  span {
    display: inline-block;
    margin-top: 1px;
  }
  svg {
    margin-left: 10px;
    margin-right: -5px;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  margin-bottom: 10px;
`;

const W8BENEForm = () => {
  let [authState, setAuthState] = useGlobal("authState");
  let [, setCountries] = useGlobal("countries");

  let [name, setName] = useState("");
  let [country, setCountry] = useState(null);
  let [chapter3Status, setChapter3Status] = useState(null);
  let [addressLine1, setAddressLine1] = useState("");
  let [addressPostcode, setAddressPostcode] = useState("");
  let [addressCityState, setAddressCityState] = useState("");
  let [address2Line1, setAddress2Line1] = useState("");
  let [address2Postcode, setAddress2Postcode] = useState("");
  let [address2CityState, setAddress2CityState] = useState("");
  let [address2Country, setAddress2Country] = useState(null);
  let [tin, setTin] = useState("");
  let [foreignTin, setForeignTin] = useState("");
  let [lobProvision, setLobProvision] = useState("");
  let [otherLobProvision, setOtherLobProvision] = useState("");
  let [signature, setSignature] = useState("");
  let [error, setError] = useState("");
  let [working, setWorking] = useState(false);
  let [done, setDone] = useState(false);

  let [taxTables, setTaxTables] = useState(null);

  useEffect(() => {
    async function getTaxTables() {
      const request = await axios.post(apiRoot, {
        query: `
        {
          taxTables {
            country
            royaltyRate
            specialRatesArticle
          }
        }`,
      });
      setTaxTables(request.data.data.taxTables);
    }
    getTaxTables();
  }, [setTaxTables]);

  const validate = (text) => {
    const validCharacters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 &-,'/.";
    for (const i of text.split("")) {
      if (!validCharacters.split("").includes(i)) return false;
    }
    return true;
  };

  const submit = async () => {
    if (working) return;
    if (!name) return setError("You must state your entity's name on line 1!");
    if (!country)
      return setError("You must list your country of organization!");
    if (!chapter3Status)
      return setError("You must select your chapter 3 status/entity type!");

    if (!addressLine1 || !addressCityState)
      return setError("You must list your address!");
    if (
      (address2Line1 || address2CityState || address2Country) &&
      !(address2Line1 && address2CityState && address2Country)
    )
      return setError(
        "If you list a mailing address, you must include all mailing address components!"
      );
    if (!tin && !foreignTin)
      return setError(
        "You must include either a US or foreign tax identification number!"
      );
    if (tin && tin.replace(/-/g, "").length !== 9)
      return setError("Your TIN must be 9 digits long!");
    if (!signature)
      return setError("You must sign the certification statement!");
    if (
      !validate(name) ||
      !validate(addressCityState) ||
      !validate(addressLine1) ||
      !validate(addressPostcode) ||
      !validate(address2Line1) ||
      !validate(address2CityState) ||
      !validate(address2Postcode) ||
      !validate(foreignTin) ||
      !validate(otherLobProvision) ||
      !validate(signature)
    )
      return setError(
        "All inputs must only contain valid characters (alphanumeric, spaces, hyphens, apostrophes, commas, periods, ampersands). If your name or address includes a special character, use the closest English language alternative."
      );
    setWorking(true);
    // Actually submit the form
    let encData = {};
    if (tin) encData.tin = tin;
    if (foreignTin) encData.foreignTin = foreignTin;
    const enc = await encrypt(encData);
    let unenc = {
      name,
      country,
      residenceAddressLine1: addressLine1,
      residenceAddressCityState: addressCityState,
      residenceAddressPostcode: addressPostcode,
      residenceAddressCountry: country,
      mailingAddressLine1: address2Line1,
      mailingAddressCityState: address2CityState,
      mailingAddressPostcode: address2Postcode,
      mailingAddressCountry: address2Country,
      chapter3Status,
      certificationName: signature,
    };
    if (taxCountry) unenc.treatyCountry = country;
    if (lobProvision) {
      if (lobProvision === "other")
        unenc.treatyLimitationOnBenefits = otherLobProvision;
      else unenc.treatyLimitationOnBenefits = lobProvision;
    }
    const res = await axios.post(
      apiRoot,
      {
        query: `
        mutation addTaxForm($id: Int!, $unenc: W8BENEInput!, $enc: String!) {
          paymentProfile(id: $id) {
            addTaxInfo(type: W8BENE, unencryptedW8BENE: $unenc, encryptedInfo: $enc)
          }
        }`,
        variables: {
          id: authState.paymentProfile.id,
          unenc,
          enc,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
    if (res.data.errors) return setError(res.data.errors[0].message);
    await getAuthState(setAuthState, setCountries);
    setDone(true);
  };

  const [countries] = useGlobal("countries");
  let taxCountry =
    taxTables && taxTables.filter((i) => i.country === country)[0];
  return (
    <div>
      {done && <Redirect to="/dashboard" />}
      <FormContainer>
        <FormTitle>
          FORM W-8BEN-E{" "}
          <span>
            Certificate of Status of Beneficial Owner for United States Tax
            Withholding and Reporting (Entities) <small>(rev. July 2017)</small>
          </span>
        </FormTitle>
        <ExternalLink
          target="_blank"
          href="https://www.irs.gov/instructions/iw8bene"
        >
          Instructions for Form W-8BEN-E - Internal Revenue Service
        </ExternalLink>
        <PartLabel>
          <strong>PART I</strong>Identification of Beneficial Owner
        </PartLabel>
        <Flex>
          <InputGroup>
            <InputLabel htmlFor="line1">
              <strong>Line 1 </strong>Name of organization that is the
              beneficial owner
            </InputLabel>
            <Input
              id="line1"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </InputGroup>
          <HorizontalSpacer />
          <InputGroup>
            <InputLabel>
              <strong>Line 2 </strong>Country of incorporation or organization
            </InputLabel>
            <ItemSelect
              items={countries.filter((i) => i !== "United States")}
              value={country}
              onChange={(i) => setCountry(i)}
              disallowNone
            />
          </InputGroup>
        </Flex>
        <InputLabel>
          <strong>Line 4 </strong>Chapter 3 Status (entity type)
        </InputLabel>
        <Spacer />
        <ButtonSelect
          options={[
            { id: "corporation", text: "Corporation" },
            { id: "disregarded", text: "Disregarded entity" },
            {
              id: "partnership",
              text: "Partnership",
            },
            {
              id: "simpletrust",
              text: "Simple trust",
            },
            {
              id: "grantor",
              text: "Grantor trust",
            },
            {
              id: "complex",
              text: "Complex trust",
            },
            {
              id: "estate",
              text: "Estate",
            },
            { id: "government", text: "Government" },
            { id: "centralbank", text: "Central Bank of Issue" },
            { id: "taxexempt", text: "Tax-exempt organization" },
            { id: "foundation", text: "Private foundation" },
            { id: "intorg", text: "International organization" },
          ]}
          active={chapter3Status}
          setActive={(e) => setChapter3Status(e)}
        />
        {(chapter3Status === "disregarded" ||
          chapter3Status === "partnership" ||
          chapter3Status === "simpletrust" ||
          chapter3Status === "grantor") && (
          <InputLabel>
            Only submit this form if the entity is a hybrid making a treaty
            claim. Otherwise, you may need to submit a W-8BEN, W-8IMY, or other
            form.
          </InputLabel>
        )}
        <InputLabel htmlFor="line6Address">
          <strong>Line 6 </strong>Permanent residence address (street, apt. or
          suite no., or rural route). Do not use a P.O. box or in-care-of
          address.
        </InputLabel>
        <Input
          id="line6Address"
          value={addressLine1}
          onChange={(e) => setAddressLine1(e.target.value)}
        />
        <Flex>
          <InputGroup>
            <InputLabel htmlFor="line6CityState">
              City or town, state or province.
            </InputLabel>
            <Input
              id="line6CityState"
              value={addressCityState}
              onChange={(e) => setAddressCityState(e.target.value)}
            />
          </InputGroup>
          <HorizontalSpacer />
          <InputGroup>
            <InputLabel htmlFor="line6Postcode">Postal code</InputLabel>
            <Input
              id="line6Postcode"
              value={addressPostcode}
              onChange={(e) => setAddressPostcode(e.target.value)}
            />
          </InputGroup>
          <HorizontalSpacer />
          <InputGroup>
            <InputLabel>Country</InputLabel>
            <span
              style={{
                color: "rgba(255,255,255,0.8)",
                margin: "4px 0 0 0",
                fontWeight: "500",
                display: "block",
              }}
            >
              {country}
            </span>
          </InputGroup>
        </Flex>
        <InputLabel htmlFor="line7Address">
          <strong>Line 7 </strong>Mailing address (if different from above)
        </InputLabel>
        <Input
          id="line7Address"
          value={address2Line1}
          onChange={(e) => setAddress2Line1(e.target.value)}
        />
        <Flex>
          <InputGroup>
            <InputLabel htmlFor="line7CityState">
              City or town, state or province.
            </InputLabel>
            <Input
              id="line7CityState"
              value={address2CityState}
              onChange={(e) => setAddress2CityState(e.target.value)}
            />
          </InputGroup>
          <HorizontalSpacer />
          <InputGroup>
            <InputLabel htmlFor="line7Postcode">Postal code</InputLabel>
            <Input
              id="line7Postcode"
              value={address2Postcode}
              onChange={(e) => setAddress2Postcode(e.target.value)}
            />
          </InputGroup>
          <HorizontalSpacer />
          <InputGroup>
            <InputLabel>Country</InputLabel>
            <ItemSelect
              items={countries}
              value={address2Country}
              onChange={(i) => setAddress2Country(i)}
              disallowNone
            />
          </InputGroup>
        </Flex>
        <Flex>
          <InputGroup>
            <InputLabel htmlFor="line8">
              <strong>Line 8 </strong>U.S. taxpayer identification number (TIN),
              if required
            </InputLabel>
            <InputMask
              mask="99-9999999"
              maskChar=""
              value={tin}
              onChange={(e) => setTin(e.target.value)}
            >
              {(inputProps) => <Input id="line5" {...inputProps}></Input>}
            </InputMask>
          </InputGroup>
          <HorizontalSpacer />
          <InputGroup>
            <InputLabel htmlFor="line9">
              <strong>Line 9b </strong>Foreign tax identification number
            </InputLabel>
            <Input
              id="line9"
              value={foreignTin}
              onChange={(e) => setForeignTin(e.target.value)}
            />
          </InputGroup>
        </Flex>
        <PartLabel>
          <strong>PART III</strong>Claim of Tax Treaty Benefits
        </PartLabel>
        {!country && (
          <InputLabel>
            This section will be automatically filled based on the country you
            select.
          </InputLabel>
        )}
        {country && !taxCountry && (
          <InputLabel>
            No tax treaty exists between your country of residence and the
            United States, so you do not need to complete this section. We will
            be required to withhold 30% of your earnings from US sources and
            remit this withholding tax to the US Internal Revenue Service.
          </InputLabel>
        )}
        {taxCountry && (
          <InputLabel>
            I certify that the beneficial owner is a resident of{" "}
            <strong>{country}</strong>within the meaning of the income tax
            treaty between the United States and that country.
          </InputLabel>
        )}
        {taxCountry && !taxCountry.specialRatesArticle && (
          <InputLabel>
            The withholding rate applied to royalties under this treaty is{" "}
            {taxCountry.royaltyRate * 100}%. We will withhold this rate on all
            royalties derived from uses within the United States before paying
            you.
          </InputLabel>
        )}
        {taxCountry && (
          <InputLabel>
            The beneficial owner derives the item (or items) of income for which
            the treaty benefits are claimed, and, if applicable, meets the
            requirements of the treaty provision dealing with limitation on
            benefits. The following are types of limitation on benefits
            provisions that may be included in an applicable tax treaty (select
            one if applicable):
            <Spacer />
          </InputLabel>
        )}
        {taxCountry && (
          <ButtonSelect
            options={[
              { id: "government", text: "Government" },
              {
                id: "pension",
                text: "Tax exempt pension trust or pension fund",
              },
              { id: "taxexempt", text: "Tax exempt organization" },
              { id: "public", text: "Publicly traded corporation" },
              {
                id: "subsidiary",
                text: "Subsidiary of a publicly traded corporation",
              },
              {
                id: "ownership",
                text: "Company that meets the ownership and base erosion test",
              },
              {
                id: "derivative",
                text: "Company that meets the derivative benefits test",
              },
              {
                id: "active",
                text: "Company with an item of income that meets active trade or business test",
              },
              {
                id: "favorable",
                text: "Favorable discretionary determination by the U.S. competent authority received",
              },
              { id: "other", text: "Other" },
            ]}
            active={lobProvision}
            setActive={(e) => setLobProvision(e)}
          />
        )}
        {lobProvision === "other" && (
          <InputGroup>
            <InputLabel for="otherLob">
              Specify Article and paragraph:
            </InputLabel>
            <Input
              id="otherLob"
              value={otherLobProvision}
              onChange={(e) => setOtherLobProvision(e.target.value)}
            />
          </InputGroup>
        )}
        {taxCountry && taxCountry.specialRatesArticle && (
          <InputLabel>
            <strong>Special rates and conditions: </strong>The beneficial owner
            is claiming the provisions of Article and paragraph{" "}
            {taxCountry.specialRatesArticle} of the treaty identified above to
            claim a {taxCountry.royaltyRate * 100}% rate of withholding on
            royalties.
          </InputLabel>
        )}
        {taxCountry && taxCountry.specialRatesArticle && (
          <InputLabel>
            Explain the additional conditions in the Article and paragraph the
            beneficial owner meets to be eligible for the rate of withholding:
            The beneficial owner collects musical copyright royalties for uses
            within the United States as a client of Music Inc. As copyright
            royalties for creative work, these royalties fall under the rate
            identified above based on the terms of the article and paragraph of
            the identified treaty.
          </InputLabel>
        )}
        <PartLabel>
          <strong>PART XXX</strong>Certification
        </PartLabel>
        <Certification>
          Under penalties of perjury, I declare that I have examined the
          information on this form and to the best of my knowledge and belief it
          is true, correct, and complete. I further certify under penalties of
          perjury that:
          <Spacer />
          The entity identified on line 1 of this form is the beneficial owner
          of all the income to which this form relates,
          <Spacer />
          The entity named on line 1 of this form is not a U.S. person,
          <Spacer />
          The income to which this form relates is:
          <br />
          (a) not effectively connected with the conduct of a trade or business
          in the United States,
          <br />
          (b) effectively connected but is not subject to tax under an
          applicable income tax treaty, or
          <br />
          (c) the partner’s share of a partnership's effectively connected
          income,
          <Spacer />
          Furthermore, I authorize this form to be provided to any withholding
          agent that has control, receipt, or custody of the income of which the
          entity on line 1 is the beneficial owner or any withholding agent that
          can disburse or make payments of the income of which the entity on
          line 1 is the beneficial owner. I agree that I will submit a new form
          within 30 days if any certification made on this form becomes
          incorrect.
        </Certification>
        <InputLabel htmlFor="certification">
          <strong>Signature</strong> (type your name here - this constitutes a
          legally binding electronic signature)
        </InputLabel>
        <Input
          id="certification"
          value={signature}
          onChange={(e) => setSignature(e.target.value)}
        />
        <InputLabel>
          By providing your signature, you certify that you have the capacity to
          sign for the entity identified on line 1 of this form.
        </InputLabel>
      </FormContainer>
      <br />
      {error && (
        <ErrorMsg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="hsl(5, 100%, 70%)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          {error}
        </ErrorMsg>
      )}
      <Button onClick={submit}>
        <span>Submit tax form</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          height="24"
          width="24"
        >
          <path
            fill="white"
            d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
          />
        </svg>
      </Button>
      <InputLabel>
        Your personal information is secure, and is always transmitted and
        stored in an encrypted form.
      </InputLabel>
    </div>
  );
};

export default W8BENEForm;
