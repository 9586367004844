import React, { useState } from "react";
import { useEffect, useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import getAuthState from "../../getAuthState";
import axios from "axios";
import apiRoot from "../../apiRoot";
import InputMask from "react-input-mask";
import ItemSelect from "../../components/ItemSelect";
import states from "../AddTaxForm/states";
import Checkbox from "../../components/Checkbox";
import Tooltip from "../../components/Tooltip";

const InputLabel = styled.label`
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  margin-top: 10px;
  strong {
    margin-right: 5px;
    color: rgba(255, 255, 255, 0.8);
  }
`;

const Input = styled.input`
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: border-color 0.2s;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  padding: 4px 10px;
  width: 100%;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  background: transparent;
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.5);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5);
  }
  :focus {
    border-color: rgba(255, 255, 255, 0.2);
  }
`;

const Button = styled.button`
  padding: 6px 25px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  line-height: 1;
  :hover {
    filter: brightness(110%);
  }
  span {
    display: inline-block;
    margin-top: 1px;
  }
  svg {
    margin-left: 10px;
    margin-right: -5px;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  margin-bottom: 10px;
`;

const Flex = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const InputGroup = styled.div`
  flex: 1;
`;

const HorizontalSpacer = styled.div`
  margin-right: 15px;
`;

const Check = () => {
  const [authState, setAuthState] = useGlobal("authState");
  const [, setCountries] = useGlobal("countries");

  let [preloaded, setPreloaded] = useState(false);
  let [payTo, setPayTo] = useState("");
  let [line1, setLine1] = useState("");
  let [line2, setLine2] = useState("");
  let [city, setCity] = useState("");
  let [state, setState] = useState(null);
  let [zip, setZip] = useState("");
  let [error, setError] = useState("");
  let [working, setWorking] = useState(false);
  let [done, setDone] = useState(false);
  let [autoPayout, setAutoPayout] = useState(false);

  useEffect(() => {
    const preload = async () => {
      const res = await axios.post(
        apiRoot,
        {
          query: `query getTaxAddress($id: Int!) {
          paymentProfile(id: $id) {
            taxAddressLine1
            taxAddressCity
            taxAddressState
            taxAddressZip
          }
        }`,
          variables: { id: authState.paymentProfile.id },
        },
        { withCredentials: true }
      );
      let p;
      if (res.data.data.paymentProfile) {
        p = res.data.data.paymentProfile;
        if (p.taxAddressLine1) setLine1(p.taxAddressLine1);
        if (p.taxAddressCity) setCity(p.taxAddressCity);
        if (p.taxAddressState) setState(p.taxAddressState);
        if (p.taxAddressZip) setZip(p.taxAddressZip);
      }
    };
    if (!preloaded) {
      preload();
      setPreloaded(true);
    }
  }, [preloaded, authState?.paymentProfile?.id]);

  const submit = async () => {
    if (working) return;
    if (!payTo) return setError("You must include the payee name!");
    if (!line1) return setError("You must include your address!");
    if (!city || !state || !zip || zip.length < 5)
      return setError("You must include your city, state, and ZIP code!");
    const res = await axios.post(
      apiRoot,
      {
        query: `
    mutation addPaymentMethod($id: Int!, $info: PaymentMethodInput!, $ap: Boolean!) {
      paymentProfile(id: $id) {
        addPaymentMethod(type: check, unencryptedInfo: $info, autoPayout: $ap)
      }
    }`,
        variables: {
          id: authState.paymentProfile.id,
          info: {
            payTo,
            addressLine1: line1,
            addressLine2: line2,
            addressCity: city,
            addressState: state,
            addressZip: Number(zip),
          },
          ap: autoPayout,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
    if (res.data.errors) return setError(res.data.errors[0].message);
    await getAuthState(setAuthState, setCountries);
    setDone(true);
  };

  return (
    <div style={{ marginTop: "20px", maxWidth: "790px" }}>
      {done && <Redirect to="/dashboard" />}
      <InputLabel htmlFor="payTo">Pay to the order of</InputLabel>
      <Input
        id="payTo"
        value={payTo}
        onChange={(e) => setPayTo(e.target.value)}
      />
      <InputLabel htmlFor="line5">Address Line 1</InputLabel>
      <Input
        id="line5"
        value={line1}
        onChange={(e) => setLine1(e.target.value)}
      />
      <InputLabel htmlFor="addressline2">Address Line 2</InputLabel>
      <Input
        id="addressLine2"
        value={line2}
        onChange={(e) => setLine2(e.target.value)}
      />
      <Flex>
        <InputGroup>
          <InputLabel htmlFor="line6">City</InputLabel>
          <Input
            id="line6"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </InputGroup>
        <HorizontalSpacer />
        <InputGroup>
          <InputLabel>State</InputLabel>
          <ItemSelect
            items={states}
            value={state}
            onChange={(i) => setState(i)}
            disallowNone
          />
        </InputGroup>
        <HorizontalSpacer />
        <InputGroup>
          <InputLabel htmlFor="zip">ZIP Code</InputLabel>
          <InputMask
            mask="99999"
            maskChar=""
            value={zip}
            onChange={(e) => setZip(e.target.value)}
          >
            {(inputProps) => <Input id="zip" {...inputProps}></Input>}
          </InputMask>
        </InputGroup>
      </Flex>
      <br />
      <Checkbox
        active={autoPayout}
        setActive={() => setAutoPayout(!autoPayout)}
      >
        Enable auto-payout
        <Tooltip>
          With auto-payout enabled, we'll automatically pay out your balance to
          this payment method whenever your balance reaches the threshold.
          Otherwise, you'll need to manually request a payout when you want one,
          but can choose any payment method you've added.
        </Tooltip>
      </Checkbox>
      <br />
      {error && (
        <ErrorMsg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="hsl(5, 100%, 70%)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          {error}
        </ErrorMsg>
      )}
      <Button onClick={submit}>
        <span>Add payment method</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          height="24"
          width="24"
        >
          <path
            fill="white"
            d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
          />
        </svg>
      </Button>
    </div>
  );
};

export default Check;
