import React, { useEffect } from "react";
import { useGlobal } from "reactn";
import { Route as BaseRoute, Switch, withRouter } from "react-router-dom";
import styled from "styled-components";

import Header from "./components/Header";
import Home from "./pages/home/Home";
import Dashboard from "./pages/dashboard/Dashboard";
import Settings from "./pages/settings/Settings";
import AddTaxForm from "./pages/AddTaxForm/AddTaxForm";
import AddPaymentMethod from "./pages/AddPaymentMethod/AddPaymentMethod";
import SetElectronicTaxDelivery from "./pages/SetElectronicTaxDelivery/SetElectronicTaxDelivery";
import TaxForms from "./pages/TaxForms/TaxForms";
import getAuthState from "./getAuthState";
import NotFound from "./pages/NotFound";

class ScrollToTopRoute extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return <BaseRoute {...rest} render={(props) => <Component {...props} />} />;
  }
}

const Route = withRouter(ScrollToTopRoute);

const AppFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainFlex = styled.main`
  padding-top: 80px;
  flex: 1;
  display: flex;
  min-height: calc(100vh - 80px);
`;

function App() {
  // Determine auth status on app load
  // Set user and publishing account as a global variable
  // authState { loading (boolean), authenticated (boolean), setupStatus, user, publishingAccounts, publishingAccount }
  let [, setAuthState] = useGlobal("authState");
  let [, setCountries] = useGlobal("countries");
  useEffect(
    () => getAuthState(setAuthState, setCountries),
    [setAuthState, setCountries]
  );

  return (
    <AppFlex>
      <Header />
      <MainFlex>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/settings/:profileId" component={Settings} />
          <Route path="/addTaxForm" component={AddTaxForm} />
          <Route path="/addPaymentMethod" component={AddPaymentMethod} />
          <Route
            path="/setElectronicTaxDelivery"
            component={SetElectronicTaxDelivery}
          />
          <Route path="/taxforms" component={TaxForms} />
          <Route component={NotFound} />
        </Switch>
      </MainFlex>
    </AppFlex>
  );
}

export default App;
