import React from "react";
import { Redirect } from "react-router-dom";
import { useGlobal } from "reactn";

const Home = () => {
  // check if logged in
  // if yes, redirect to /dashboard
  // otherwise redirect to /login
  const [authState] = useGlobal("authState");
  if (!authState) return <div />;
  if (!authState.loading && authState.authenticated)
    return <Redirect to="/dashboard" />;
  return <div />;
};

export default Home;
