import React, { useState } from "react";
import styled from "styled-components";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import axios from "axios";
import apiRoot from "../../apiRoot";
import ButtonSelect from "../../components/ButtonSelect";
import getAuthState from "../../getAuthState";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 20px 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  color: white;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Title = styled.h1`
  @media screen and (max-width: 600px) {
    font-size: 22px;
  }
`;

const Subtitle = styled.p`
  max-width: 800px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const Dot = styled.div`
  background: ${(props) =>
    props.active
      ? "linear-gradient(135deg, hsl(160, 95%, 55%), hsl(220, 92%, 65%))"
      : "rgba(255,255,255,0.2)"};
  box-shadow: ${(props) =>
    props.active ? "0 1px 3px rgba(0,0,0,0.15)" : "none"};
  width: ${(props) => (props.current ? "14px" : "10px")};
  height: ${(props) => (props.current ? "14px" : "10px")};
  margin-right: 10px;
  border-radius: 100px;
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const Divider = styled.div`
  margin-bottom: 5px;
`;

const Button = styled.button`
  padding: 6px 25px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  line-height: 1;
  margin-top: 20px;
  &:disabled {
    background: rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.8);
    cursor: default;
  }
  :hover {
    filter: brightness(110%);
  }
  span {
    display: inline-block;
    margin-top: 1px;
  }
  svg {
    margin-left: 10px;
    margin-right: -5px;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  margin: 10px 0;
`;

const SetElectronicTaxDelivery = () => {
  const [authState, setAuthState] = useGlobal("authState");
  const [, setCountries] = useGlobal("countries");

  const [accept, setAccept] = useState();
  let [error, setError] = useState("");
  let [working, setWorking] = useState(false);
  let [done, setDone] = useState(false);

  const submit = async () => {
    if (working || (accept !== true && accept !== false)) return;
    setWorking(true);
    const res = await axios.post(
      apiRoot,
      {
        query: `
      mutation addTaxForm($id: Int!, $accept: Boolean!) {
        paymentProfile(id: $id) {
          setElectronicTaxConsent(value: $accept)
        }
      }`,
        variables: {
          id: authState.paymentProfile.id,
          accept,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
    if (res.data.errors) setError(res.data.errors[0].message);
    await getAuthState(setAuthState, setCountries);
    setDone(true);
  };

  if (
    (authState &&
      !authState.loading &&
      authState.authenticated &&
      authState.paymentProfile &&
      authState.paymentProfile.setupStatus !== "needs_electronic_tax_request" &&
      authState.paymentProfile.setupStatus !== "done" &&
      (!JSON.parse(localStorage.getItem("skip_add_payment_method")) ||
        !JSON.parse(localStorage.getItem("skip_add_payment_method")).includes(
          authState.paymentProfile.id
        ))) ||
    (authState &&
      authState.paymentProfiles &&
      authState.paymentProfiles.length === 0)
  ) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <MainComponent>
      {done && <Redirect to="/dashboard" />}
      {authState &&
        authState.paymentProfile &&
        authState.paymentProfile.setupStatus !== "done" && (
          <DotContainer>
            <Dots>
              <Dot active={true} current={false} />
              <Dot active={true} current={true} />
              <Dot active={false} current={false} />
            </Dots>
          </DotContainer>
        )}
      <Title>Set Tax Document Delivery Method</Title>
      <Subtitle>
        We're required to send you a tax form at the end of the year summarizing
        your payouts - a Form 1099-MISC for US clients, and a 1042-S for
        international clients. Select below whether or not you would like your
        tax documents to be delivered electronically. We strongly recommend
        electronic delivery - when you opt in to electronic tax document
        delivery, your tax documents are available immediately upon posting and
        are easy to access and maintain, without having to worry about keeping
        mailing addresses accurate and keeping track of papers.
      </Subtitle>
      <Subtitle>
        The following details apply to electronically delivered tax statements:
        <br />
        <br />
        - We will maintain the availability of your tax documents indefinitely.
        You will be able to access documents for all past years through the
        Music Inc. payment portal.
        <Divider />
        - In order to access your statements, all that is required is Internet
        access, a modern web browser, and the ability to view PDF formatted
        documents.
        <Divider />
        - Your consent to electronic delivery will last until it is terminated.
        If you wish to receive a paper copy, you can change your delivery method
        from your payment profile settings at any time.
        <Divider />- If you do not consent to electronic delivery, mailed
        statements will be sent to the address listed as your mailing address on
        your tax form (W-8 or W-9). You can view your address and submit a new
        tax form from your payment profile settings.
      </Subtitle>
      <Subtitle>
        Would you like to accept electronic delivery of your tax statements?
      </Subtitle>
      <ButtonSelect
        options={[
          { id: true, text: "Yes, deliver my statements electronically" },
          { id: false, text: "No, mail my end of year tax statements" },
        ]}
        active={accept}
        setActive={setAccept}
      />
      {error && (
        <ErrorMsg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="hsl(5, 100%, 70%)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          {error}
        </ErrorMsg>
      )}
      <div>
        <Button
          disabled={(accept !== true && accept !== false) || working}
          onClick={submit}
        >
          Submit{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            height="24"
            width="24"
          >
            <path
              fill="white"
              d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
            />
          </svg>
        </Button>
      </div>
    </MainComponent>
  );
};

export default SetElectronicTaxDelivery;
