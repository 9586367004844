import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import Tooltip from "../../components/Tooltip";

const TransactionComponent = styled.div`
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
`;

const DepositBorder = styled.div`
  width: 4px;
  background: linear-gradient(0deg, hsl(201, 93%, 42%), hsl(185, 95%, 51%));
  border-radius: 3px 0 0 3px;
`;

const WithdrawalBorder = styled.div`
  width: 4px;
  background: linear-gradient(0deg, hsl(10, 93%, 37%), hsl(35, 95%, 51%));
  border-radius: 3px 0 0 3px;
`;

const UpperSection = styled.div`
  padding: 7px 15px 10px 15px;
  width: 100%;
`;

const TransactionTitle = styled.h5`
  margin: 0;
  font-size: 18px;
  display: inline-block;
  @media screen and (max-width: 900px) {
    font-size: 16px;
  }
`;

const DateSpan = styled.span`
  font-weight: 500;
  font-size: 14px;
  margin-right: 15px;
  color: rgba(255, 255, 255, 0.7);
  @media screen and (max-width: 900px) {
    font-size: 12px;
  }
`;

const Subtitle = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  margin-top: 5px;
`;

const Amount = styled.div`
  color: white;
  font-weight: 700;
  font-size: 18px;
  padding-left: 20px;
  white-space: nowrap;
  @media screen and (max-width: 900px) {
    font-size: 14px;
    margin: 5px 0 10px 0;
    color: rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, 0.08);
    padding: 2px 10px;
    border-radius: 3px;
  }
`;

const AlignedTitle = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: flex-start;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Trigger = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.4px;
  padding: 2px 12px;
  margin-left: 6px;
  position: relative;
  top: -3px;
  cursor: default;
`;

const accountTypes = {
  businessSavings: "business savings",
  businessChecking: "business checking",
  personalSavings: "personal savings",
  personalChecking: "personal checking",
};

const Transaction = (props) => {
  return (
    <TransactionComponent>
      {props.transaction.__typename === "Deposit" ? (
        <DepositBorder />
      ) : (
        <WithdrawalBorder />
      )}
      <UpperSection>
        <AlignedTitle>
          <div>
            <DateSpan
              title={format(
                new Date(props.transaction.created_at),
                "MMMM do, yyyy hh:mm aa"
              )}
            >
              {format(new Date(props.transaction.created_at), "yyyy-MM-dd")}
            </DateSpan>
            {props.transaction.__typename === "Deposit" ? (
              <TransactionTitle>{props.transaction.source}</TransactionTitle>
            ) : (
              <TransactionTitle>
                {props.transaction.automatic ? "Automatic Payout" : "Payout"}
              </TransactionTitle>
            )}
            {props.transaction.__typename === "Withdrawal" &&
              !props.transaction.paid && (
                <Tooltip
                  bottom={30}
                  customTooltip={() => <Trigger>PENDING</Trigger>}
                >
                  This payout has not yet been sent. It can take up to 7 days
                  for a payout to be processed.
                </Tooltip>
              )}
          </div>
          <Amount>
            {props.transaction.__typename === "Deposit" ? "+ " : "- "}$
            {props.transaction.amount.toFixed(2)}
          </Amount>
        </AlignedTitle>
        {props.transaction.__typename === "Deposit" && (
          <Subtitle>{props.transaction.comment}</Subtitle>
        )}
        {props.transaction.__typename === "Withdrawal" &&
          props.transaction.tax_withheld > 0 && (
            <Subtitle>
              ${props.transaction.tax_withheld.toFixed(2)} of tax withheld - $
              {props.transaction.amount_paid.toFixed(2)} paid out
            </Subtitle>
          )}
        {props.transaction.__typename === "Withdrawal" &&
          !props.transaction.payment_method && (
            <Subtitle>Direct withdrawal by Music Inc.</Subtitle>
          )}
        {props.transaction.__typename === "Withdrawal" &&
          props.transaction.payment_method?.type === "check" && (
            <Subtitle>
              Paid via check sent to{" "}
              {props.transaction.payment_method.addressLine1},{" "}
              {props.transaction.payment_method.addressCity},{" "}
              {props.transaction.payment_method.addressState}{" "}
              {props.transaction.payment_method.addressZip}
            </Subtitle>
          )}
        {props.transaction.__typename === "Withdrawal" &&
          props.transaction.payment_method?.type === "us_bank" && (
            <Subtitle>
              Paid via ACH deposit to your{" "}
              {accountTypes[props.transaction.payment_method.bankAccountType]}{" "}
              account ending in{" "}
              {props.transaction.payment_method.accountNumberLast4} at{" "}
              {props.transaction.payment_method.bankName}
            </Subtitle>
          )}
        {props.transaction.__typename === "Withdrawal" &&
          props.transaction.payment_method?.type === "paypal" && (
            <Subtitle>
              Paid via PayPal to {props.transaction.payment_method.paypalEmail}
            </Subtitle>
          )}
      </UpperSection>
    </TransactionComponent>
  );
};

export default Transaction;
