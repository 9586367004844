import React, { useState } from "react";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import getAuthState from "../../getAuthState";
import axios from "axios";
import apiRoot from "../../apiRoot";
import ButtonSelect from "../../components/ButtonSelect";
import encrypt from "../AddTaxForm/encrypt";
import Checkbox from "../../components/Checkbox";
import Tooltip from "../../components/Tooltip";

const InputLabel = styled.label`
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  margin-top: 10px;
  margin-bottom: 3px;
  strong {
    margin-right: 5px;
    color: rgba(255, 255, 255, 0.8);
  }
  a {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Input = styled.input`
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: border-color 0.2s;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  padding: 4px 10px;
  width: 100%;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  background: transparent;
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.5);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5);
  }
  :focus {
    border-color: rgba(255, 255, 255, 0.2);
  }
`;

const Button = styled.button`
  padding: 6px 25px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  line-height: 1;
  :hover {
    filter: brightness(110%);
  }
  span {
    display: inline-block;
    margin-top: 1px;
  }
  svg {
    margin-left: 10px;
    margin-right: -5px;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  margin-bottom: 10px;
`;

const Flex = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const InputGroup = styled.div`
  flex: 1;
`;

const HorizontalSpacer = styled.div`
  margin-right: 15px;
`;

const ACH = () => {
  const [authState, setAuthState] = useGlobal("authState");
  const [, setCountries] = useGlobal("countries");

  let [type, setType] = useState(null);
  let [bankName, setBankName] = useState("");
  let [routing, setRouting] = useState("");
  let [account, setAccount] = useState("");
  let [error, setError] = useState("");
  let [working, setWorking] = useState(false);
  let [done, setDone] = useState(false);
  let [autoPayout, setAutoPayout] = useState(false);

  const submit = async () => {
    if (working) return;
    if (!type) return setError("You must select an account type!");
    if (!bankName) return setError("You must include your bank's name!");
    if (!routing)
      return setError("You must include your bank's routing number!");
    if (routing.length !== 9)
      return setError("Your routing number must be 9 digits!");
    if (!account) return setError("You must include your account number!");
    // Validate routing number
    try {
      const req = await axios.get(
        "https://api.transferwise.com/v1/validators/abartn?abartn=" + routing
      );
      if (req.data.errors) return setError("Invalid routing number!");
    } catch (err) {
      return setError("Invalid routing number!");
    }
    const res = await axios.post(
      apiRoot,
      {
        query: `
    mutation addPaymentMethod($id: Int!, $info: PaymentMethodInput!, $enc: String!, $ap: Boolean!) {
      paymentProfile(id: $id) {
        addPaymentMethod(type: us_bank, unencryptedInfo: $info, encryptedInfo: $enc, autoPayout: $ap)
      }
    }`,
        variables: {
          id: authState.paymentProfile.id,
          info: {
            bankAccountType: type,
            bankName,
            routingNumber: routing,
            accountNumberLast4: String(account.substr(account.length - 4)),
          },
          enc: await encrypt({ accountNumber: account }),
          ap: autoPayout,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
    if (res.data.errors) setError(res.data.errors[0].message);
    await getAuthState(setAuthState, setCountries);
    setDone(true);
  };

  return (
    <div style={{ marginTop: "20px", maxWidth: "790px" }}>
      {done && <Redirect to="/dashboard" />}
      <InputLabel>Bank account type</InputLabel>
      <ButtonSelect
        options={[
          { id: "personalChecking", text: "Personal Checking" },
          { id: "personalSavings", text: "Personal Savings" },
          { id: "businessChecking", text: "Business Checking" },
          { id: "businessSavings", text: "Business Savings" },
        ]}
        active={type}
        setActive={setType}
      />
      <InputLabel htmlFor="bankName">Bank name</InputLabel>
      <Input
        id="bankName"
        value={bankName}
        onChange={(e) => setBankName(e.target.value)}
      />
      <Flex>
        <InputGroup>
          <InputLabel htmlFor="routing">Routing number</InputLabel>
          <Input
            id="routing"
            value={routing}
            onChange={(e) =>
              setRouting(
                e.target.value
                  .split("")
                  .filter((i) => "0123456789".split("").includes(i))
                  .join("")
                  .substr(0, 9)
              )
            }
          />
        </InputGroup>
        <HorizontalSpacer />
        <InputGroup>
          <InputLabel htmlFor="account">Account number</InputLabel>
          <Input
            id="account"
            value={account}
            onChange={(e) =>
              setAccount(
                e.target.value
                  .split("")
                  .filter((i) => "0123456789".split("").includes(i))
                  .join("")
              )
            }
          />
        </InputGroup>
      </Flex>
      <InputLabel>
        Not sure where to find these numbers? Check your online banking account,{" "}
        <a
          href="https://www.nationwide.com/lc/resources/personal-finance/articles/routing-and-account-numbers"
          target="_blank"
          rel="noreferrer"
        >
          find them on your checks
        </a>
        , or ask your bank.
        <br />
        Your banking information is always end-to-end encrypted and stored
        securely.
      </InputLabel>
      <br />
      <Checkbox
        active={autoPayout}
        setActive={() => setAutoPayout(!autoPayout)}
      >
        Enable auto-payout
        <Tooltip>
          With auto-payout enabled, we'll automatically pay out your balance to
          this payment method whenever your balance reaches the threshold.
          Otherwise, you'll need to manually request a payout when you want one,
          but can choose any payment method you've added.
        </Tooltip>
      </Checkbox>
      <br />
      {error && (
        <ErrorMsg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="hsl(5, 100%, 70%)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          {error}
        </ErrorMsg>
      )}
      <Button onClick={submit}>
        <span>Add payment method</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          height="24"
          width="24"
        >
          <path
            fill="white"
            d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
          />
        </svg>
      </Button>
    </div>
  );
};

export default ACH;
