import React, { useState } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import HeaderDropdown from "./HeaderDropdown";

const Container = styled.div`
  background: #342939;
  height: 80px;
  box-shadow: 0 4px 12px 2px rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  z-index: 11;
`;

const Inner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.svg`
  margin-left: -20px;
`;

const RightSide = styled.div`
  display: flex;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const MobileRightSide = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    display: flex;
  }
`;

const Open = styled.svg`
  cursor: pointer;
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  height: calc(100vh - 80px);
  width: 100%;
  background: #342939;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  box-sizing: border-box;
`;

const MobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderLink = styled(Link)`
  color: ${(props) => (props.active ? "white" : "rgba(255,255,255,0.7);")};
  border-bottom: 2px solid
    ${(props) => (props.active ? "white" : "transparent")};
  text-decoration: none;
  height: 80px;
  padding: 0 15px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  margin-right: 15px;
  transition: color 0.2s, border-bottom 0.2s;
  @media screen and (max-width: 800px) {
    border-bottom: none;
    text-align: center;
    padding: 10px;
    margin-right: 0;
    height: auto;
  }
`;

const LogoContainer = styled.div`
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: white;
  cursor: default;
`;

const Header = (props) => {
  let [authState, setAuthState] = useGlobal("authState");
  let [hideHeader] = useGlobal("hideHeader");
  let [showMobile, setShowMobile] = useState(false);

  if (hideHeader) return <div />;
  return (
    <Container>
      <Inner>
        <LogoContainer>
          <Logo
            width="90"
            height="90"
            viewBox="0 0 140 140"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48.2626 35.9688C48.2623 34.5441 49.4156 33.2079 50.8251 33H54.2621V56.5626V90.9688C54.2969 92.6453 53.731 94.8298 52.9814 96.7813C51.5123 99.7522 48.9754 102.386 45.6376 104.313C42.2998 106.24 38.7262 107.151 35.4188 106.938C32.1116 106.724 28.8502 105.248 27.1376 102.281C25.4251 99.3151 25.7937 95.7835 27.2626 92.8127C28.7315 89.8418 31.2998 87.1772 34.6376 85.2501C37.9754 83.3231 41.5178 82.4431 44.8251 82.6564C45.9997 82.7322 47.1669 82.9756 48.2626 83.3751V35.9688Z"
              fill="white"
            />
            <path
              d="M96.5 106.5V62.6174L80.3 89.7974H72.38C72.38 89.7974 55.73 47.8974 53.5 47.7074C51.27 47.5174 52.3995 68.155 53 71.7074V96.0074V33.0074C53 33.0074 48.44 33.0074 54.47 33.0074C60.5 33.0074 76.61 69.3674 76.61 69.3674L98.21 33.0074H112.88L112.88 106.5H96.5Z"
              fill="white"
            />
          </Logo>
          Payments
        </LogoContainer>
        <RightSide>
          {authState &&
            authState.paymentProfile &&
            (authState.paymentProfile.setupStatus === "done" ||
              (JSON.parse(localStorage.getItem("skip_add_payment_method")) &&
                JSON.parse(
                  localStorage.getItem("skip_add_payment_method")
                ).includes(authState.paymentProfile.id))) && (
              <div>
                {authState.paymentProfile && (
                  <HeaderLink
                    to="/dashboard"
                    active={props.location.pathname.includes("dashboard")}
                  >
                    Dashboard
                  </HeaderLink>
                )}
                {authState.paymentProfile &&
                  authState.paymentProfile.taxForms &&
                  authState.paymentProfile.taxForms.length > 0 && (
                    <HeaderLink
                      to="/taxforms"
                      active={props.location.pathname.includes("taxforms")}
                    >
                      Tax Statements
                    </HeaderLink>
                  )}
                {authState.paymentProfile && (
                  <HeaderLink
                    to={"/settings/" + authState.paymentProfile.id}
                    active={props.location.pathname.includes("settings")}
                  >
                    Settings
                  </HeaderLink>
                )}
              </div>
            )}
          {authState && authState.authenticated && (
            <HeaderDropdown
              authState={authState}
              setPaymentProfile={(paymentProfile) => {
                setAuthState({ ...authState, paymentProfile });
                localStorage.setItem(
                  "active_payment_profiles",
                  JSON.stringify({
                    ...JSON.parse(
                      localStorage.getItem("active_payment_profiles")
                    ),
                    [authState.user.id]: paymentProfile.id,
                  })
                );
              }}
            />
          )}
        </RightSide>
        <MobileRightSide>
          <Open
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="32"
            height="32"
            onClick={() => setShowMobile(!showMobile)}
          >
            {showMobile ? (
              <path
                fill="white"
                d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
              />
            ) : (
              <path
                fill="white"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              />
            )}
          </Open>
          {showMobile && (
            <MobileMenu>
              {authState &&
                authState.paymentProfile &&
                (authState.paymentProfile.setupStatus === "done" ||
                  (JSON.parse(
                    localStorage.getItem("skip_add_payment_method")
                  ) &&
                    JSON.parse(
                      localStorage.getItem("skip_add_payment_method")
                    ).includes(authState.paymentProfile.id))) && (
                  <MobileLinks>
                    {authState.paymentProfile && (
                      <HeaderLink
                        to="/dashboard"
                        active={props.location.pathname.includes("dashboard")}
                        onClick={() => setShowMobile(false)}
                      >
                        Dashboard
                      </HeaderLink>
                    )}
                    {authState.paymentProfile && (
                      <HeaderLink
                        to={"/settings/" + authState.paymentProfile.id}
                        active={props.location.pathname.includes("settings")}
                        onClick={() => setShowMobile(false)}
                      >
                        Settings
                      </HeaderLink>
                    )}
                  </MobileLinks>
                )}
              {authState && authState.authenticated && (
                <HeaderDropdown
                  authState={authState}
                  setPaymentProfile={(paymentProfile) => {
                    setAuthState({ ...authState, paymentProfile });
                    localStorage.setItem(
                      "active_payment_profiles",
                      JSON.stringify({
                        ...JSON.parse(
                          localStorage.getItem("active_payment_profiles")
                        ),
                        [authState.user.id]: paymentProfile.id,
                      })
                    );
                  }}
                />
              )}
            </MobileMenu>
          )}
        </MobileRightSide>
      </Inner>
    </Container>
  );
};

export default withRouter(Header);
