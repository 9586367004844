import React, { useState } from "react";
import ButtonSelect from "../../components/ButtonSelect";
import Paypal from "./Paypal";
import ACH from "./ACH";
import Check from "./Check";
import InternationalWire from "./InternationalWire";

const SelectPaymentMethod = (props) => {
  let [paymentMethod, setPaymentMethod] = useState(null);

  return (
    <div style={{ paddingBottom: "30px" }}>
      <ButtonSelect
        options={[
          { id: "ach", text: "ACH Direct Deposit" },
          { id: "check", text: "Paper Check" },
          { id: "paypal", text: "PayPal" },
          { id: "wire", text: "International Wire Transfer" },
        ]}
        active={paymentMethod}
        setActive={setPaymentMethod}
      />
      {paymentMethod === "paypal" && <Paypal />}
      {paymentMethod === "ach" && <ACH />}
      {paymentMethod === "check" && <Check />}
      {paymentMethod === "wire" && <InternationalWire />}
    </div>
  );
};

export default SelectPaymentMethod;
