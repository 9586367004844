import React from "react";
import styled from "styled-components";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 80px 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 240px);
  text-align: center;
  @media screen and (max-width: 400px) {
    padding: 40px 20px;
  }
`;

const Title = styled.h3`
  color: white;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 24px;
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  font-size: 14px;
  max-width: 600px;
  margin: 0 auto;
`;

const NotFound = () => {
  return (
    <MainComponent>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="icon-important"
        width="64"
        height="64"
      >
        <path
          fill="rgba(255,255,255,0.2)"
          d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
        />
        <path
          fill="rgba(255,255,255,0.9)"
          d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
        />
      </svg>
      <Title>We couldn't find what you're looking for!</Title>
      <Subtitle>
        Seems like something's gone wrong - whatever you were expecting to find
        here isn't here. Make sure your URL is correct, and check that you're
        logged into the correct publishing account if you think you should have
        access to something here.
      </Subtitle>
    </MainComponent>
  );
};

export default NotFound;
