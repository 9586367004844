import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import ButtonSelect from "../../components/ButtonSelect";

const ModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 11;
`;

const Modal = styled.div`
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  max-height: 800px;
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  background: linear-gradient(135deg, hsl(184, 93%, 37%), hsl(211, 95%, 51%));
  padding: 15px;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  font-weight: 700;
`;

const ModalMain = styled.div`
  background: #342939;
  padding: 15px;
  overflow-y: auto;
  h4 {
    margin: 0 0 10px 0;
    color: rgba(255, 255, 255, 0.8);
  }
  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  small {
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
    margin-left: 5px;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.5);
  }
`;

const Text = styled.p`
  margin: 0;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-bottom: 10px;
  a {
    color: inherit;
  }
`;

const Button = styled.button`
  padding: 10px 25px;
  background: linear-gradient(135deg, hsl(184, 93%, 37%), hsl(211, 95%, 51%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  line-height: 1;
  :hover {
    filter: brightness(110%);
  }
  span {
    display: inline-block;
    margin-top: 1px;
  }
  svg {
    margin-left: 10px;
    margin-right: -5px;
  }
  &:disabled {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.4);
    box-shadow: none;
    cursor: default;
  }
`;

const LightButton = styled(Button)`
  transition: background 0.2s;
  background: rgba(255, 255, 255, 0.1);
  :hover {
    background: rgba(255, 255, 255, 0.13);
  }
`;

const PaymentMethodInner = styled.div`
  display: flex;
  padding: 5px 0;
`;

const Icon = styled.div`
  svg {
    width: 40px;
    height: 40px;
  }
  margin-right: 15px;
`;

const MethodText = styled.div``;

const MethodType = styled.h5`
  margin: 0 0 5px 0;
  font-size: 14px;
`;

const SubDetail = styled.span`
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
`;

const accountTypes = {
  businessSavings: "business savings",
  businessChecking: "business checking",
  personalSavings: "personal savings",
  personalChecking: "personal checking",
};

const RequestPayout = (props) => {
  let [loading, setLoading] = useState(true);
  let [showTax, setShowTax] = useState(false);
  let [activeMethod, setActiveMethod] = useState(null);
  let [working, setWorking] = useState(false);
  let [data, setData] = useState(null);

  useEffect(() => {
    async function getData() {
      const response = await axios.post(
        apiRoot,
        {
          query: `
            query getDashboardInfo($id: Int!) {
              paymentProfile(id: $id) {
                paymentMethods {
                  id
                  type
                  paypalEmail
                  bankName
                  routingNumber
                  swift
                  wireAccountNumberType
                  accountNumberLast4
                  bankAccountType
                  payTo
                  addressLine1
                  addressLine2
                  addressCity
                  addressState
                  addressZip
                }
              }
            }`,
          variables: { id: props.id },
        },
        { withCredentials: true }
      );
      if (response?.data?.data?.paymentProfile) {
        setLoading(false);
        setData(response.data.data.paymentProfile);
      }
    }
    getData();
  }, [props.id]);

  const submit = async () => {
    if (working) return;
    setWorking(true);
    await axios.post(
      apiRoot,
      {
        query: `
          mutation requestpayout($id: Int!, $pm: Int!) {
            paymentProfile(id: $id) {
              requestPayout(paymentMethod: $pm)
            }
          }`,
        variables: { id: props.id, pm: activeMethod },
      },
      { withCredentials: true }
    );
    setWorking(false);
    props.refresh();
    props.close();
  };

  let bal = Number(
    (
      props.paymentProfile.balance - props.paymentProfile.withholdingAmount
    ).toFixed(2)
  );

  let payable = bal >= 10;
  if (
    activeMethod &&
    data.paymentMethods &&
    data.paymentMethods.filter((i) => i.id === activeMethod)[0].type ===
      "international_wire" &&
    bal < 100
  )
    payable = false;

  return (
    <ModalBg>
      <Modal>
        <ModalHeader>Request Payout</ModalHeader>
        {loading || !data ? (
          <ModalMain>
            <Text>Loading...</Text>
          </ModalMain>
        ) : (
          <ModalMain>
            <Text>
              <ButtonContainer>
                <span>
                  {props.paymentProfile.withholdingAmount
                    ? "Balance"
                    : "Payable Balance"}
                </span>
                <strong>${props.paymentProfile.balance.toFixed(2)}</strong>
              </ButtonContainer>
              {props.paymentProfile.withholdingAmount ? (
                <div>
                  {showTax && (
                    <div>
                      <ButtonContainer>
                        <span>Earnings from US Sources</span>
                        <strong>
                          ${props.paymentProfile.us_balance.toFixed(2)}
                        </strong>
                      </ButtonContainer>
                      <ButtonContainer>
                        <span>
                          Royalty Tax Withholding Rate (
                          {props.paymentProfile.withholdingInfo.country})
                        </span>
                        <strong>
                          {props.paymentProfile.withholdingInfo.royaltyRate *
                            100}
                          %
                        </strong>
                      </ButtonContainer>
                    </div>
                  )}
                  <ButtonContainer>
                    <span>
                      US Tax Withholding
                      {!showTax && (
                        <small onClick={() => setShowTax(true)}>
                          (more info)
                        </small>
                      )}
                    </span>
                    <strong>
                      -${props.paymentProfile.withholdingAmount.toFixed(2)}
                    </strong>
                  </ButtonContainer>
                  <ButtonContainer>
                    <span>Payable Balance</span>
                    <strong>
                      $
                      {(
                        props.paymentProfile.balance -
                        props.paymentProfile.withholdingAmount
                      ).toFixed(2)}
                    </strong>
                  </ButtonContainer>
                </div>
              ) : (
                <div />
              )}
            </Text>
            {Number(
              (
                props.paymentProfile.balance -
                props.paymentProfile.withholdingAmount
              ).toFixed(2)
            ) < 10 && (
              <Text>
                <br />
                Your balance is under $10! You won't be able to request a payout
                until your balance reaches the $10 USD threshold.
              </Text>
            )}
            {Number(
              (
                props.paymentProfile.balance -
                props.paymentProfile.withholdingAmount
              ).toFixed(2)
            ) > 5000 && (
              <Text>
                <br />
                You have a balance of above $5000. High balance payouts may need
                to be made using a different payout method, as some of our
                standard payout methods don't support high balances. We'll get
                in touch if we need to arrange alternate payment for your
                balance.
              </Text>
            )}
            {Number(
              (
                props.paymentProfile.balance -
                props.paymentProfile.withholdingAmount
              ).toFixed(2)
            ) >= 10 && <h4>Select Payout Method</h4>}
            {data.paymentMethods.length === 0 && (
              <Text>
                <br />
                You have no payment methods!{" "}
                <Link to="/addPaymentMethod">Add a payment method</Link> to
                request a payout.
              </Text>
            )}
            {data &&
              Number(
                (
                  props.paymentProfile.balance -
                  props.paymentProfile.withholdingAmount
                ).toFixed(2)
              ) >= 10 && (
                <ButtonSelect
                  fullWidth
                  options={data.paymentMethods.map((i) => ({
                    id: i.id,
                    text: (() => {
                      if (i.type === "us_bank") {
                        return (
                          <PaymentMethodInner>
                            <Icon>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                viewBox="0 0 100 100"
                              >
                                <path
                                  fill="rgba(255,255,255,0.7)"
                                  d="M90.1,80.1v-8.4c0-0.8-0.6-1.4-1.4-1.4h-7.4v-33h7.4c0.8,0,1.4-0.6,1.4-1.4V24.4c0-0.5-0.3-1-0.8-1.3L50.5,6.2    c-0.3-0.2-0.7-0.2-1.1,0L10.7,23.1c-0.5,0.2-0.8,0.7-0.8,1.3V36c0,0.8,0.6,1.4,1.4,1.4h8v33h-8c-0.8,0-1.4,0.6-1.4,1.4v8.4h-6    c-0.8,0-1.4,0.6-1.4,1.4v11c0,0.8,0.6,1.4,1.4,1.4h92.3c0.8,0,1.4-0.6,1.4-1.4v-11c0-0.8-0.6-1.4-1.4-1.4H90.1z M13.9,33.4v-7.3    L50,10.4l36.1,15.8v7.3H13.9z M77.3,37.4v33h-7.6v-33H77.3z M65.7,37.4v33h-7.6v-33H65.7z M54.1,37.4v33h-7.6v-33H54.1z     M42.5,37.4v33h-7.6v-33H42.5z M30.8,37.4v33h-7.6v-33H30.8z M13.9,74.4h72.2v5.7H13.9V74.4z M93.5,89.9h-87v-5.7h87V89.9z"
                                />
                              </svg>
                            </Icon>
                            <MethodText>
                              <MethodType>ACH Bank Transfer</MethodType>
                              <SubDetail>
                                {accountTypes[i.bankAccountType]} account at{" "}
                                {i.bankName} ending in {i.accountNumberLast4}
                              </SubDetail>
                            </MethodText>
                          </PaymentMethodInner>
                        );
                      }
                      if (i.type === "international_wire") {
                        return (
                          <PaymentMethodInner>
                            <Icon>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                viewBox="0 0 100 100"
                              >
                                <path
                                  fill="rgba(255,255,255,0.7)"
                                  d="M90.1,80.1v-8.4c0-0.8-0.6-1.4-1.4-1.4h-7.4v-33h7.4c0.8,0,1.4-0.6,1.4-1.4V24.4c0-0.5-0.3-1-0.8-1.3L50.5,6.2    c-0.3-0.2-0.7-0.2-1.1,0L10.7,23.1c-0.5,0.2-0.8,0.7-0.8,1.3V36c0,0.8,0.6,1.4,1.4,1.4h8v33h-8c-0.8,0-1.4,0.6-1.4,1.4v8.4h-6    c-0.8,0-1.4,0.6-1.4,1.4v11c0,0.8,0.6,1.4,1.4,1.4h92.3c0.8,0,1.4-0.6,1.4-1.4v-11c0-0.8-0.6-1.4-1.4-1.4H90.1z M13.9,33.4v-7.3    L50,10.4l36.1,15.8v7.3H13.9z M77.3,37.4v33h-7.6v-33H77.3z M65.7,37.4v33h-7.6v-33H65.7z M54.1,37.4v33h-7.6v-33H54.1z     M42.5,37.4v33h-7.6v-33H42.5z M30.8,37.4v33h-7.6v-33H30.8z M13.9,74.4h72.2v5.7H13.9V74.4z M93.5,89.9h-87v-5.7h87V89.9z"
                                />
                              </svg>
                            </Icon>
                            <MethodText>
                              <MethodType>
                                International Wire Transfer
                              </MethodType>
                              <SubDetail>
                                SWIFT code {i.swift},{" "}
                                {i.wireAccountNumberType === "IBAN"
                                  ? "IBAN"
                                  : "account number"}{" "}
                                ending in {i.accountNumberLast4}
                              </SubDetail>
                            </MethodText>
                          </PaymentMethodInner>
                        );
                      }
                      if (i.type === "paypal") {
                        return (
                          <PaymentMethodInner>
                            <Icon>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 100"
                              >
                                <path
                                  fill="rgba(255,255,255,0.4)"
                                  d="M47.77,63.24a.5.5,0,0,1,.49-.41H60A18.62,18.62,0,0,0,78.23,47.89l2.12-10.58A11.26,11.26,0,0,0,70.7,23.93c0,.08,0,.16,0,.25L68.55,34.76A19.64,19.64,0,0,1,49.34,50.5H38L32.75,76.76v0L30.2,89.5H42.52Z"
                                />
                                <path
                                  fill="rgba(255,255,255,0.7)"
                                  d="M31.85,76.17,37.11,49.9a.49.49,0,0,1,.49-.4H49.34A18.64,18.64,0,0,0,67.57,34.56L69.68,24s0-.08,0-.12,0-.33.07-.5a4.29,4.29,0,0,0,.07-.5A11.26,11.26,0,0,0,58.63,10.5h-26L19.54,76.17Z"
                                />
                              </svg>
                            </Icon>
                            <MethodText>
                              <MethodType>PayPal</MethodType>
                              <SubDetail>{i.paypalEmail}</SubDetail>
                            </MethodText>
                          </PaymentMethodInner>
                        );
                      }
                      if (i.type === "check") {
                        return (
                          <PaymentMethodInner>
                            <Icon>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                viewBox="0 0 64 64"
                              >
                                <path
                                  fill="rgba(255,255,255,0.7)"
                                  d="M55,55.3H40c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h15c0.5,0,0.9,0.4,0.9,0.9C55.9,54.9,55.5,55.3,55,55.3z"
                                />
                                <path
                                  fill="rgba(255,255,255,0.7)"
                                  d="M24,40.4H9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h15c0.5,0,0.9,0.4,0.9,0.9C24.9,40,24.5,40.4,24,40.4z"
                                />
                                <path
                                  fill="rgba(255,255,255,0.7)"
                                  d="M37.6,45.4H9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h28.4L37.6,45.4z"
                                />
                                <path
                                  fill="rgba(255,255,255,0.7)"
                                  d="M55.9,44.5c0,0.5-0.4,0.9-0.9,0.9H43.6l0.9-0.8c0.3-0.3,0.5-0.6,0.7-1l0,0H55C55.5,43.6,55.9,44,55.9,44.5z"
                                />
                                <path
                                  fill="rgba(255,255,255,0.7)"
                                  d="M55.9,49.5c0,0.5-0.4,0.9-0.9,0.9H9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9H55C55.5,48.5,55.9,49,55.9,49.5z"
                                />
                                <path
                                  fill="rgba(255,255,255,0.7)"
                                  d="M60.1,6.8l-0.3-0.1C60,6,59.6,5.2,58.9,4.9l-0.3-0.1l0.8-2c0.2-0.4,0-0.8-0.4-1l-1.6-0.7c-0.4-0.2-0.8,0-1,0.4l-0.8,2   l-0.3-0.1c-0.8-0.3-1.7,0-2.1,0.8l-7.6,18.2c-0.3,0.8-0.2,1.6,0.1,2.3l-6.8,16.2C39,41.2,39,41.4,39,41.5l0.2,5.2   c0,0.2,0.2,0.3,0.3,0.1l3.9-3.5c0.1-0.1,0.2-0.2,0.3-0.4l6.8-16.2c0.8-0.3,1.4-0.8,1.7-1.6L59.3,8l0.2,0.1c1.2,0.5,1.8,1.9,1.3,3.1   l-6.6,15.8c-0.1,0.4,0,0.8,0.4,0.9c0.4,0.1,0.8,0,0.9-0.4l6.6-15.8C62.9,9.8,62,7.6,60.1,6.8z"
                                />
                                <path
                                  fill="rgba(255,255,255,0.7)"
                                  d="M61,31.1H50.4l-1.2,3h10.4v25.8H4.6V34h35.7l1.2-3H3c-0.8,0-1.5,0.7-1.5,1.5v28.8c0,0.8,0.7,1.5,1.5,1.5h58   c0.8,0,1.5-0.7,1.5-1.5V32.6C62.5,31.7,61.8,31.1,61,31.1z"
                                />
                              </svg>
                            </Icon>
                            <MethodText>
                              <MethodType>Paper Check</MethodType>
                              <SubDetail>Payable to {i.payTo}</SubDetail>
                              <SubDetail>
                                Mailed to {i.addressLine1}, {i.addressCity},{" "}
                                {i.addressState} {i.addressZip}
                              </SubDetail>
                            </MethodText>
                          </PaymentMethodInner>
                        );
                      }
                    })(),
                  }))}
                  active={activeMethod}
                  setActive={setActiveMethod}
                />
              )}
            <br />
            {activeMethod &&
              data.paymentMethods &&
              data.paymentMethods.filter((i) => i.id === activeMethod)[0]
                .type === "international_wire" &&
              bal < 100 && (
                <Text>
                  Your balance is under $100! You can't request a payout by
                  international wire transfer until your balance reaches $100
                  USD.
                  <br />
                </Text>
              )}
            {payable ? (
              <ButtonContainer>
                <LightButton onClick={props.close}>Close</LightButton>
                <Button disabled={!activeMethod} onClick={submit}>
                  Request Payout
                </Button>
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <LightButton onClick={props.close}>Close</LightButton>
              </ButtonContainer>
            )}
          </ModalMain>
        )}
      </Modal>
    </ModalBg>
  );
};

export default RequestPayout;
