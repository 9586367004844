import React, { useState } from "react";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import getAuthState from "../../getAuthState";
import axios from "axios";
import apiRoot from "../../apiRoot";
import Tooltip from "../../components/Tooltip";
import encrypt from "../AddTaxForm/encrypt";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import PhInput from "react-phone-number-input/input";
import { validateBIC, validateIBAN, extractBIC } from "ibantools";
import Checkbox from "../../components/Checkbox";

// prettier-ignore
const legalWireCodes = ["AO", "AI", "AG", "AR", "AM", "AW", "AU", "AT", "BH", "BD", "BB", "BY", "BE", "BZ", "BJ", "BM", "BT", "BR", "BN", "BG", "BF", "CM", "CA", "KY", "TD", "CL", "CN", "CO", "CG", "CK", "CR", "HR", "CW", "CY", "CZ", "DK", "DJ", "DM", "EC", "EG", "SV", "GQ", "EE", "FJ", "FI", "FR", "PF", "GA", "GM", "GE", "DE", "GH", "GR", "GL", "GP", "HK", "HU", "IS", "IN", "ID", "IE", "IL", "IT", "JM", "JP", "JE", "JO", "KZ", "KE", "KI", "KR", "XK", "KW", "KG", "LV", "LS", "LI", "LT", "LU", "MO", "MK", "MG", "MW", "MY", "MV", "ML", "MT", "MU", "MX", "MD", "MC", "MN", "ME", "MS", "MA", "NA", "NP", "NL", "NC", "NZ", "NG", "NO", "OM", "OK", "PG", "PY", "PE", "PH", "PL", "PT", "QA", "RO", "RU", "RW", "LC", "VC", "SN", "RS", "SC", "SL", "SG", "SK", "SI", "SB", "ZA", "ES", "SR", "SZ", "SE", "CH", "TW", "TZ", "TH", "TL", "TG", "TR", "TC", "UG", "AE", "GB", "UY", "UZ", "VN", "VG", "ZM"]
// prettier-ignore
const ibanCountries = ["AD","AT","BH","BE","BA","BG","HR","CY","CZ","DK","EE","FO","FI","FR","GF","GP","MQ","RE","PF","TF","YT","NC","BL","MF","PM","WF","GE","DE","GI","GB","IM","GG","GR","GL","HU","IS","IE","IT","JO","KZ","KW","LV","LB","LI","LT","LU","MK","MT","MD","MC","ME","NL","NO","PS","PL","PT","QA","RO","SM","SA","SK","SI","ES","SE","CH","TN","TR","AE","AL","BR","CR","DO","GT","IR","IL","XK","KW","MG","MR","MU","PK","ST","RS","UA","VG","TL"]

/*

trying to figure out mercury international wire
IBAN: "AD","AT","BH","BE","BA","BG","HR","CY","CZ","DK","EE","FO","FI","FR","GF","GP","MQ","RE","PF","TF","YT","NC","BL","MF","PM","WF","GE","DE","GI","GB","IM","GG","GR","GL","HU","IS","IE","IT","JO","KZ","KW","LV","LB","LI","LT","LU","MK","MT","MD","MC","ME","NL","NO","PS","PL","PT","QA","RO","SM","SA","SK","SI","ES","SE","CH","TN","TR","AE","AL","BR","CR","DO","GT","IR","IL","XK","KW","MG","MR","MU","PK","ST","RS","UA","VG","TL"
CLABE: MX
otherwise account number
no wires to ??? countries i can't find them

*/

const InputLabel = styled.label`
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  margin-top: 10px;
  margin-bottom: 3px;
  strong {
    margin-right: 5px;
    color: rgba(255, 255, 255, 0.8);
  }
  a {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Input = styled.input`
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: border-color 0.2s;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  padding: 4px 10px;
  width: 100%;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  background: transparent;
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.5);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5);
  }
  :focus {
    border-color: rgba(255, 255, 255, 0.2);
  }
`;

const Button = styled.button`
  padding: 6px 25px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  line-height: 1;
  :hover {
    filter: brightness(110%);
  }
  span {
    display: inline-block;
    margin-top: 1px;
  }
  svg {
    margin-left: 10px;
    margin-right: -5px;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  margin-bottom: 10px;
`;

const InputGroup = styled.div`
  flex: 1;
`;

const InternationalWire = () => {
  const [authState, setAuthState] = useGlobal("authState");
  const [, setCountries] = useGlobal("countries");

  let [swift, setSwift] = useState("");
  let [iban, setIban] = useState("");
  let [account, setAccount] = useState("");
  let [caBankCode, setCaBankCode] = useState("");
  let [caTransitNum, setCaTransitNum] = useState("");
  let [auBsbCode, setAuBsbCode] = useState("");
  let [inIfscCode, setInIfscCode] = useState("");
  let [ruInn, setRuInn] = useState("");
  let [phRouting, setPhRouting] = useState("");
  let [zaBranchCode, setZaBranchCode] = useState("");
  let [phone, setPhone] = useState("");

  let [error, setError] = useState("");
  let [working, setWorking] = useState(false);
  let [done, setDone] = useState(false);
  let [autoPayout, setAutoPayout] = useState(false);

  let showIban = true;
  if (
    swift &&
    validateBIC(swift).valid &&
    !ibanCountries.includes(extractBIC(swift).countryCode)
  )
    showIban = false;
  const illegalWire =
    swift &&
    validateBIC(swift).valid &&
    !legalWireCodes.includes(extractBIC(swift).countryCode);
  const countryCode =
    swift && validateBIC(swift).valid && extractBIC(swift).countryCode;

  const submit = async () => {
    if (working) return;
    if (!swift) return setError("You must include your bank's SWIFT/BIC code!");
    if (showIban && !iban) return setError("You must include your IBAN!");
    if (!showIban && !account)
      return setError("You must include your account number!");
    if (!phone) return setError("You must include your phone number!");
    if (!isPossiblePhoneNumber(phone))
      return setError("Your phone number is invalid!");
    if (
      swift &&
      validateBIC(swift).valid &&
      !legalWireCodes.includes(extractBIC(swift).countryCode)
    )
      return setError("We can't send wire transfers to your country.");
    // Validate SWIFT, IBAN
    if (!validateBIC(swift).valid)
      return setError("Your SWIFT/BIC code is invalid!");
    if (showIban && !validateIBAN(iban).valid)
      return setError("Your IBAN is invalid!");
    let obj = { phone };
    if (showIban) obj.iban = iban;
    else obj.account = account;
    // Validate country specific info
    if (countryCode === "CA") {
      // CA bank code - 3 digits
      if (!caBankCode || caBankCode.length !== 3)
        return setError("Bank code must be 3 digits!");
      // CA transit # - 5 digits
      if (!caTransitNum || caTransitNum.length !== 5)
        return setError("Transit number must be 5 digits!");
      obj.caBankCode = caBankCode;
      obj.caTransitNum = caTransitNum;
    }
    if (countryCode === "AU") {
      // AU BSB - 6 digits
      if (!auBsbCode || auBsbCode.length !== 6)
        return setError("BSB code must be 6 digits!");
      obj.auBsb = auBsbCode;
    }
    if (countryCode === "IN") {
      // IN IFSC - 4 alphabet characters + 0 + 6 alphanumeric
      if (!inIfscCode || inIfscCode.length !== 11)
        return setError("IFSC Code must be 11 characters!");
      if (inIfscCode[4] !== "0") return setError("Invalid IFSC!");
      obj.inIfscCode = inIfscCode;
    }
    if (countryCode === "RU") {
      // RU INN - 10-12 digits
      if (!ruInn || (ruInn.length !== 10 && ruInn.length !== 12))
        return setError("Invalid INN!");
      obj.ruInn = ruInn;
    }
    if (countryCode === "PH") {
      // PH Routing # - 9 digits
      if (!phRouting || phRouting.length !== 9)
        return setError("Routing number must be 9 digits!");
      obj.phRouting = phRouting;
    }
    if (countryCode === "ZA") {
      // ZA Branch Code - 6 digits
      if (!zaBranchCode || zaBranchCode.length !== 6)
        return setError("Branch code must be 6 digits!");
      obj.zaBranchCode = zaBranchCode;
    }
    const res = await axios.post(
      apiRoot,
      {
        query: `
    mutation addPaymentMethod($id: Int!, $info: PaymentMethodInput!, $enc: String!, $ap: Boolean!) {
      paymentProfile(id: $id) {
        addPaymentMethod(type: international_wire, unencryptedInfo: $info, encryptedInfo: $enc, autoPayout: $ap)
      }
    }`,
        variables: {
          id: authState.paymentProfile.id,
          info: {
            swift,
            wireAccountNumberType: showIban ? "IBAN" : "account",
            accountNumberLast4: showIban
              ? iban.substr(iban.length - 4)
              : account.substr(iban.length - 4),
          },
          enc: await encrypt(obj),
          ap: autoPayout,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
    if (res.data.errors) setError(res.data.errors[0].message);
    await getAuthState(setAuthState, setCountries);
    setDone(true);
  };

  return (
    <div style={{ marginTop: "20px", maxWidth: "790px" }}>
      {done && <Redirect to="/dashboard" />}
      <InputGroup>
        <InputLabel htmlFor="routing">
          SWIFT/BIC code
          <Tooltip>
            The SWIFT/BIC code is a code used internationally to identify your
            bank or financial institution. It's 8 to 11 characters in length.
            You can likely find it on your bank statements.
          </Tooltip>
        </InputLabel>
        <Input
          id="routing"
          value={swift}
          onChange={(e) =>
            setSwift(
              e.target.value
                .toUpperCase()
                .split("")
                .filter((i) =>
                  "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("").includes(i)
                )
                .join("")
                .substr(0, 11)
            )
          }
        />
      </InputGroup>
      {showIban && (
        <InputGroup>
          <InputLabel htmlFor="iban">
            IBAN
            <Tooltip>
              The IBAN (International Bank Account Number) is a unique global
              identifier for your bank account, which we can use to route your
              payments via international wire transfer. Check your bank
              statement to find it.
            </Tooltip>
          </InputLabel>
          <Input
            id="iban"
            value={iban}
            onChange={(e) =>
              setIban(
                e.target.value
                  .toUpperCase()
                  .split("")
                  .filter((i) =>
                    "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("").includes(i)
                  )
                  .join("")
              )
            }
          />
        </InputGroup>
      )}
      {!showIban && (
        <InputGroup>
          <InputLabel htmlFor="account">
            Account Number {countryCode === "MX" && "(CLABE)"}
            <Tooltip>
              Enter the account number provided by your bank for receiving
              funds.
            </Tooltip>
          </InputLabel>
          <Input
            id="account"
            value={account}
            onChange={(e) =>
              setAccount(
                e.target.value
                  .toUpperCase()
                  .split("")
                  .filter((i) =>
                    "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("").includes(i)
                  )
                  .join("")
              )
            }
          />
        </InputGroup>
      )}
      {countryCode === "CA" && (
        <InputGroup>
          <InputLabel htmlFor="cabankcode">
            Bank Code
            <Tooltip>
              This 3 digit code identifies banking institutions in Canada. If
              you have a routing number in the format 0AAABBBBB instead of a
              bank code, the AAA portion (digits 2-4) are your bank code.
            </Tooltip>
          </InputLabel>
          <Input
            id="cabankcode"
            value={caBankCode}
            onChange={(e) =>
              setCaBankCode(
                e.target.value
                  .split("")
                  .filter((i) => "0123456789".split("").includes(i))
                  .join("")
                  .substr(0, 3)
              )
            }
          />
        </InputGroup>
      )}
      {countryCode === "CA" && (
        <InputGroup>
          <InputLabel htmlFor="catransitnumber">
            Transit Number
            <Tooltip>
              This 5 digit code identifies your bank branch in Canada. If you
              have a routing number in the format 0AAABBBBB instead of a bank
              code, the BBBBB portion (digits 5-9) are your transit number.
            </Tooltip>
          </InputLabel>
          <Input
            id="catransitnumber"
            value={caTransitNum}
            onChange={(e) =>
              setCaTransitNum(
                e.target.value
                  .split("")
                  .filter((i) => "0123456789".split("").includes(i))
                  .join("")
                  .substr(0, 5)
              )
            }
          />
        </InputGroup>
      )}
      {countryCode === "AU" && (
        <InputGroup>
          <InputLabel htmlFor="bsb">
            BSB Code
            <Tooltip>
              The 6 digit BSB code identifies banks in Australia. Check with
              your bank to find this number.
            </Tooltip>
          </InputLabel>
          <Input
            id="bsb"
            value={auBsbCode}
            onChange={(e) =>
              setAuBsbCode(
                e.target.value
                  .split("")
                  .filter((i) => "0123456789".split("").includes(i))
                  .join("")
                  .substr(0, 6)
              )
            }
          />
        </InputGroup>
      )}
      {countryCode === "IN" && (
        <InputGroup>
          <InputLabel htmlFor="ifsc">
            IFSC Code
            <Tooltip>
              The 11 character IFSC code identifies banks in India. Check with
              your bank to find this number.
            </Tooltip>
          </InputLabel>
          <Input
            id="ifsc"
            value={inIfscCode}
            onChange={(e) =>
              setInIfscCode(
                e.target.value
                  .toUpperCase()
                  .split("")
                  .filter((i) =>
                    "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("").includes(i)
                  )
                  .join("")
                  .substr(0, 11)
              )
            }
          />
        </InputGroup>
      )}
      {countryCode === "RU" && (
        <InputGroup>
          <InputLabel htmlFor="inn">
            INN (Russian Tax ID)
            <Tooltip>
              The INN is your taxpayer personal identification number and is 10
              to 12 digits in length.
            </Tooltip>
          </InputLabel>
          <Input
            id="inn"
            value={ruInn}
            onChange={(e) =>
              setRuInn(
                e.target.value
                  .split("")
                  .filter((i) => "0123456789".split("").includes(i))
                  .join("")
                  .substr(0, 12)
              )
            }
          />
        </InputGroup>
      )}
      {countryCode === "PH" && (
        <InputGroup>
          <InputLabel htmlFor="phrouting">
            Routing Number
            <Tooltip>
              This is a 9 digit number that identifies your bank. It should be
              shown on your checks and bank statements.
            </Tooltip>
          </InputLabel>
          <Input
            id="phrouting"
            value={phRouting}
            onChange={(e) =>
              setPhRouting(
                e.target.value
                  .split("")
                  .filter((i) => "0123456789".split("").includes(i))
                  .join("")
                  .substr(0, 9)
              )
            }
          />
        </InputGroup>
      )}
      {countryCode === "ZA" && (
        <InputGroup>
          <InputLabel htmlFor="zabranch">
            Branch Code
            <Tooltip>
              This is a 6 digit number that identifies your bank. Check with
              your bank to find this code.
            </Tooltip>
          </InputLabel>
          <Input
            id="zabranch"
            value={zaBranchCode}
            onChange={(e) =>
              setZaBranchCode(
                e.target.value
                  .split("")
                  .filter((i) => "0123456789".split("").includes(i))
                  .join("")
                  .substr(0, 6)
              )
            }
          />
        </InputGroup>
      )}
      {illegalWire && (
        <ErrorMsg style={{ marginTop: "15px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="hsl(5, 100%, 70%)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          We're not able to send wire transfers to your country.
        </ErrorMsg>
      )}
      <InputGroup>
        <InputLabel htmlFor="phone">
          Phone Number
          <Tooltip>
            Our bank requires phone numbers to be provided for recipients of
            international wire transfers.
          </Tooltip>
        </InputLabel>
        <PhInput
          id="phone"
          value={phone}
          onChange={setPhone}
          inputComponent={Input}
        />
      </InputGroup>
      <InputLabel>
        Not sure where to find these numbers? Check your online banking account
        or bank statements, or ask your bank.
        <br />
        Your banking information is always end-to-end encrypted and stored
        securely.
      </InputLabel>
      <br />
      <Checkbox
        active={autoPayout}
        setActive={() => setAutoPayout(!autoPayout)}
      >
        Enable auto-payout
        <Tooltip>
          With auto-payout enabled, we'll automatically pay out your balance to
          this payment method whenever your balance reaches the threshold.
          Otherwise, you'll need to manually request a payout when you want one,
          but can choose any payment method you've added.
        </Tooltip>
      </Checkbox>
      <br />
      {error && (
        <ErrorMsg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="hsl(5, 100%, 70%)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          {error}
        </ErrorMsg>
      )}
      {!illegalWire && (
        <Button onClick={submit}>
          <span>Add payment method</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            height="24"
            width="24"
          >
            <path
              fill="white"
              d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
            />
          </svg>
        </Button>
      )}
    </div>
  );
};

export default InternationalWire;
