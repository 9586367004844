import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 400px) {
    padding: 20px;
  }
`;

const SmallTitle = styled.h4`
  font-size: 12px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
`;

const Title = styled.h2`
  font-weight: 700;
  color: rgba(255, 255, 255, 0.9);
  font-size: 24px;
  margin-top: 0;
  @media screen and (max-width: 900px) {
    font-size: 20px;
  }
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  max-width: 800px;
`;

const LightButton = styled.a`
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  padding: 5px 0;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  svg {
    margin-top: -1px;
    margin-right: 10px;
  }
  transition: color 0.2s;
  :hover {
    color: white;
    svg {
      path {
        fill: white;
      }
    }
  }
  @media screen and (max-width: 400px) {
    padding: 10px;
    margin-right: 0;
    font-size: 12px;
  }
`;

const LightButton2 = styled(LightButton)`
  color: rgba(255, 255, 255, 0.7);
  margin-left: 20px;
  margin-top: 0;
  padding: 0;
  font-size: 12px;
  :hover {
    color: rgba(255, 255, 255, 0.8);
    svg {
      path {
        fill: rgba(255, 255, 255, 0.8);
      }
    }
  }
  svg {
    margin-top: -2px;
    path {
      fill: rgba(255, 255, 255, 0.7);
    }
  }
`;

const Form = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 15px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
`;

const FormTitle = styled.h4`
  color: white;
  margin: 0;
  font-size: 20px;
  display: flex;
  align-items: center;
`;

const TaxForms = () => {
  const [data, setData] = useState(null);
  const [authState] = useGlobal("authState");

  const profileId = authState?.paymentProfile?.id;
  async function getTaxForms() {
    const response = await axios.post(
      apiRoot,
      {
        query: `
          query getTaxForms($id: Int!) {
            paymentProfile(id: $id) {
              taxForms {
                id
                tax_year
                form_type
                link
              }
            }
          }`,
        variables: { id: profileId },
      },
      { withCredentials: true }
    );
    if (response?.data?.data?.paymentProfile?.taxForms) {
      setData(response.data.data.paymentProfile.taxForms);
    }
  }

  useEffect(() => {
    if (!authState?.paymentProfile) return;
    getTaxForms();
    // eslint-disable-next-line
  }, [profileId]);

  return (
    <MainComponent>
      <Title>Tax Statements</Title>
      <Text>
        Depending on your location and the payments we make to you within a
        given year, we may be required to file one or more tax statements
        relating to payments made to you. These statements can be viewed below.
        These statements may include information which you are required to
        report on your tax return. We cannot provide tax advice - contact an
        accountant or tax preparer if you have any questions about your tax
        situation.
      </Text>
      {data &&
        data.map((form) => (
          <Form key={form.id}>
            <FormTitle>
              {form.tax_year} Form {form.form_type}
            </FormTitle>
            <LightButton2 href={form.link}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
              >
                <path
                  fill="rgba(255,255,255,0.9)"
                  d="M11 14.59V3a1 1 0 0 1 2 0v11.59l3.3-3.3a1 1 0 0 1 1.4 1.42l-5 5a1 1 0 0 1-1.4 0l-5-5a1 1 0 0 1 1.4-1.42l3.3 3.3zM3 17a1 1 0 0 1 2 0v3h14v-3a1 1 0 0 1 2 0v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-3z"
                />
              </svg>{" "}
              Download (PDF)
            </LightButton2>
          </Form>
        ))}
      {!data && <SmallTitle>Loading...</SmallTitle>}
    </MainComponent>
  );
};

export default TaxForms;
