import React, { useState } from "react";
import styled from "styled-components";
import ButtonSelect from "../../components/ButtonSelect";
import W9Form from "./W9Form";
import W8BENForm from "./W8BENForm";
import W8BENEForm from "./W8BENEForm";

const QContainer = styled.div`
  margin-top: 30px;
`;

const Question = styled.h4`
  font-weight: 700;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.9);
  margin-top: 20px;
  margin-bottom: 15px;
`;

const Subtitle = styled.p`
  max-width: 800px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  a {
    color: rgba(255, 255, 255, 0.8);
  }
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

const SelectTaxForm = () => {
  let [taxForm, setTaxForm] = useState(null);
  let [selectedTaxForm, setSelectedTaxForm] = useState(null);
  let [usChoice, setUsChoice] = useState(null);
  let [entity, setEntity] = useState(null);

  return (
    <div style={{ paddingBottom: "30px" }}>
      <Question>
        Select the tax form you need to complete based on your tax situation.
      </Question>
      <Subtitle>
        If none of these tax forms are correct for your situation (for example,
        if you're a foreign partnership that needs to submit a Form W-8IMY),
        email us at clientsupport@music.ceo with a completed copy of the
        applicable form. The forms below apply to the vast majority of our
        clients.
      </Subtitle>
      <ButtonSelect
        options={[
          { id: "W9", text: "W-9" },
          { id: "W8BEN", text: "W-8BEN" },
          { id: "W8BENE", text: "W-8BEN-E" },
          { id: "NOTSURE", text: "I don't know" },
        ]}
        active={selectedTaxForm}
        setActive={(form) => {
          setSelectedTaxForm(form);
          if (form !== "NOTSURE") setTaxForm(form);
          else if (usChoice) setTaxForm("W9");
          else if (usChoice === false && entity === true) setTaxForm("W8BENE");
          else if (usChoice === false && entity === false) setTaxForm("W8BEN");
          else setTaxForm(null);
        }}
      />
      {selectedTaxForm === "NOTSURE" && (
        <div>
          <QContainer>
            <Question>
              Are you a United States citizen or resident for tax purposes?
            </Question>
            <Subtitle>
              If you hold US citizenship, are a US permanent resident (green
              card holder), or meet the substantial presence test, choose "Yes".
              You meet the substantial presence test if you were physically
              present in the United States for at least 31 days of the current
              year and 183 days of the 3-year period preceding the current year,
              including the current year, counting all days in the current year,
              1/3 of days in the year before the current year, and 1/6 of days
              in the year two years before the current year. You can read more
              about the substantial presence test on the{" "}
              <a
                target="_blank "
                href="https://www.irs.gov/individuals/international-taxpayers/substantial-presence-test"
              >
                Internal Revenue Service website
              </a>
              .
            </Subtitle>
            <ButtonSelect
              options={[
                { id: true, text: "Yes" },
                { id: false, text: "No" },
              ]}
              active={usChoice}
              setActive={(choice) => {
                setUsChoice(choice);
                if (choice) setTaxForm("W9");
                else if (entity === true) setTaxForm("W8BENE");
                else if (entity === false) setTaxForm("W8BEN");
                else setTaxForm(null);
              }}
            />
          </QContainer>
          {usChoice === false && (
            <QContainer>
              <Question>
                Are you a foreign business entity, such as a corporation?
              </Question>
              <Subtitle>
                If you are collecting revenue from Music Inc. as a corporation
                or other legally distinct business entity based outside the
                United States, choose "Yes". If you're an individual based
                outside the US, choose "No". If you're a partnership or
                pass-through entity, you will need to complete a W-8IMY yourself
                and submit it to us by email.
              </Subtitle>
              <ButtonSelect
                options={[
                  { id: true, text: "Yes" },
                  { id: false, text: "No" },
                ]}
                active={entity}
                setActive={(choice) => {
                  setEntity(choice);
                  if (choice) setTaxForm("W8BENE");
                  else setTaxForm("W8BEN");
                }}
              />
            </QContainer>
          )}
        </div>
      )}
      {selectedTaxForm === "NOTSURE" && taxForm && (
        <div style={{ marginTop: "40px" }}>
          <Question>
            Your tax form is {taxForm === "W9" && "Form W-9"}
            {taxForm === "W8BEN" && "Form W-8BEN"}
            {taxForm === "W8BENE" && "Form W-8BEN-E"}.
          </Question>
          <Subtitle>
            Fill out the form below. If this doesn't seem to be the correct form
            for your situation, you can change your answers above, or reach out
            with any questions.
          </Subtitle>
        </div>
      )}
      {taxForm === "W9" && <W9Form />}
      {taxForm === "W8BEN" && <W8BENForm />}
      {taxForm === "W8BENE" && <W8BENEForm />}
    </div>
  );
};

export default SelectTaxForm;
