import React from "react";
import styled from "styled-components";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import SelectTaxForm from "./SelectTaxForm";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 20px 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  color: white;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 10px 20px;
  }
`;

const Title = styled.h1`
  @media screen and (max-width: 600px) {
    font-size: 22px;
  }
`;

const Subtitle = styled.p`
  max-width: 800px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const Dot = styled.div`
  background: ${(props) =>
    props.active
      ? "linear-gradient(135deg, hsl(160, 95%, 55%), hsl(220, 92%, 65%))"
      : "rgba(255,255,255,0.2)"};
  box-shadow: ${(props) =>
    props.active ? "0 1px 3px rgba(0,0,0,0.15)" : "none"};
  width: ${(props) => (props.current ? "14px" : "10px")};
  height: ${(props) => (props.current ? "14px" : "10px")};
  margin-right: 10px;
  border-radius: 100px;
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const AddTaxForm = () => {
  const [authState] = useGlobal("authState");

  if (
    (authState &&
      !authState.loading &&
      authState.authenticated &&
      authState.paymentProfile &&
      authState.paymentProfile.setupStatus !== "needs_all_details" &&
      authState.paymentProfile.setupStatus !== "done" &&
      (!JSON.parse(localStorage.getItem("skip_add_payment_method")) ||
        !JSON.parse(localStorage.getItem("skip_add_payment_method")).includes(
          authState.paymentProfile.id
        ))) ||
    (authState &&
      authState.paymentProfiles &&
      authState.paymentProfiles.length === 0)
  ) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <MainComponent>
      {authState &&
        authState.paymentProfile &&
        authState.paymentProfile.setupStatus !== "done" && (
          <DotContainer>
            <Dots>
              <Dot active={true} current={true} />
              <Dot active={false} current={false} />
              <Dot active={false} current={false} />
            </Dots>
          </DotContainer>
        )}
      <Title>Add Tax Details</Title>
      <Subtitle>
        We're legally required to obtain a tax form from you before we can pay
        you. These forms allow us to obtain the required information for sending
        your end of year informational tax returns and to avoid withholding
        taxes at a higher rate than necessary from your payouts.{" "}
        {authState?.paymentProfile?.setupStatus !== "done" &&
          "Fill out the required form below to get started setting up your payment profile."}
      </Subtitle>
      <SelectTaxForm />
    </MainComponent>
  );
};

export default AddTaxForm;
