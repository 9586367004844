import React, { useState } from "react";
import styled from "styled-components";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import SelectPaymentMethod from "./SelectPaymentMethod";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 20px 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  color: white;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Title = styled.h1`
  @media screen and (max-width: 600px) {
    font-size: 22px;
  }
`;

const Subtitle = styled.p`
  max-width: 800px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const Dot = styled.div`
  background: ${(props) =>
    props.active
      ? "linear-gradient(135deg, hsl(160, 95%, 55%), hsl(220, 92%, 65%))"
      : "rgba(255,255,255,0.2)"};
  box-shadow: ${(props) =>
    props.active ? "0 1px 3px rgba(0,0,0,0.15)" : "none"};
  width: ${(props) => (props.current ? "14px" : "10px")};
  height: ${(props) => (props.current ? "14px" : "10px")};
  margin-right: 10px;
  border-radius: 100px;
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const Button = styled.span`
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
`;

const AddPaymentMethod = () => {
  const [authState] = useGlobal("authState");
  const [skipped, setSkipped] = useState(false);

  if (
    (authState &&
      !authState.loading &&
      authState.authenticated &&
      authState.paymentProfile &&
      authState.paymentProfile.setupStatus !== "needs_payment_method" &&
      authState.paymentProfile.setupStatus !== "done") ||
    (authState &&
      authState.paymentProfiles &&
      authState.paymentProfiles.length === 0) ||
    skipped
  ) {
    return <Redirect to="/dashboard" />;
  }

  const skip = () => {
    const item = localStorage.getItem("skip_add_payment_method");
    if (item)
      localStorage.setItem(
        "skip_add_payment_method",
        JSON.stringify([...JSON.parse(item), authState.paymentProfile.id])
      );
    else
      localStorage.setItem(
        "skip_add_payment_method",
        JSON.stringify([authState.paymentProfile.id])
      );
    setSkipped(true);
  };

  return (
    <MainComponent>
      {authState &&
        authState.paymentProfile &&
        authState.paymentProfile.setupStatus !== "done" && (
          <DotContainer>
            <Dots>
              <Dot active={true} current={false} />
              <Dot active={true} current={false} />
              <Dot active={true} current={true} />
            </Dots>
          </DotContainer>
        )}
      <Title>Add Payout Method</Title>
      <Subtitle>
        Choose a payment method to add. You can always add multiple payment
        methods from your payment profile settings and select which one to use
        when you request a payout. There are no payout fees for any of these
        methods. Note that ACH transfers and paper checks are only available for
        United States bank accounts and addresses. International wire transfers
        have a minimum balance requirement of $100 to request a payout.
      </Subtitle>
      <SelectPaymentMethod
        international={authState?.paymentProfile?.taxFormType !== "W9"}
      />
      {authState &&
        authState.paymentProfile &&
        authState.paymentProfile.setupStatus !== "done" && (
          <Button onClick={skip}>Skip for now</Button>
        )}
    </MainComponent>
  );
};

export default AddPaymentMethod;
