import axios from "axios";
import apiRoot from "./apiRoot";

const getAuthState = async (setAuthState, setCountries) => {
  setAuthState({ loading: true });
  // Get user and all publishing accounts
  const authRequest = await axios.post(
    apiRoot,
    {
      query: `
      query getAuthData {
        me {
          id
          name
          email
          email_confirmed
          paymentProfiles {
            id
            name
            setupStatus
            taxFormType
            taxForms {
              id
            }
          }
        }
        countries
      }`,
    },
    { withCredentials: true }
  );
  setCountries(authRequest.data.data.countries);
  if (authRequest.data.data && authRequest.data.data.me) {
    const me = authRequest.data.data.me;
    // Check if localStorage defines a payment profile for this user ID, if logged in
    const item = localStorage.getItem("active_payment_profiles");
    let authStateTemp = {
      loading: false,
      authenticated: true,
      user: {
        id: me.id,
        name: me.name,
        email: me.email,
        email_confirmed: me.email_confirmed,
      },
      paymentProfiles: me.paymentProfiles,
    };
    if (item && me.paymentProfiles.length > 0) {
      const paymentProfileId = JSON.parse(item)[me.id];
      // If so, set
      if (
        paymentProfileId &&
        me.paymentProfiles.filter((i) => i.id === paymentProfileId)[0]
      ) {
        setAuthState({
          ...authStateTemp,
          paymentProfile: me.paymentProfiles.filter(
            (i) => i.id === paymentProfileId
          )[0],
        });
      }
      // Otherwise, use the first one and set it in localstorage
      else {
        setAuthState({
          ...authStateTemp,
          paymentProfile: me.paymentProfiles[0],
        });
        localStorage.setItem(
          "active_payment_profiles",
          JSON.stringify({
            ...JSON.parse(item),
            [me.id]: me.paymentProfiles[0].id,
          })
        );
      }
    } else if (me.paymentProfiles.length > 0) {
      setAuthState({
        ...authStateTemp,
        paymentProfile: me.paymentProfiles[0],
      });
      localStorage.setItem(
        "active_payment_profiles",
        JSON.stringify({
          [me.id]: me.paymentProfiles[0].id,
        })
      );
    } else {
      setAuthState(authStateTemp);
    }
  } else {
    window.location = "https://my.music.ceo/login/payments";
  }
};

export default getAuthState;
