const apiRoot =
  process.env.NODE_ENV === "production"
    ? "https://api.music.ceo/graphql"
    : "http://localhost:9544/graphql";
const base =
  process.env.NODE_ENV === "production"
    ? "https://api.music.ceo"
    : "http://localhost:9544";

export default apiRoot;
export { base };
