import React, { useState } from "react";
import { useGlobal } from "reactn";
import onClickOutside from "react-onclickoutside";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../apiRoot";

const UserDropdown = styled.div`
  height: 80px;
  margin-left: 10px;
  position: relative;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

const UserDropdownBase = styled.div`
  color: white;
  height: 80px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  margin-right: 10px;
`;

const Icon = styled.svg`
  border-radius: 100px;
`;

const DownArrow = styled.svg`
  margin-bottom: -2px;
  margin-left: 10px;
`;

const ExpandSection = styled.div`
  opacity: ${(props) => (props.open ? "1" : "0")};
  height: ${(props) => (props.open ? "auto" : "0")};
  pointer-events: ${(props) => (props.open ? "auto" : "none")};
  min-width: 100%;
  box-sizing: border-box;
  background: #342939;
  color: white;
  transition: opacity 0.2s;
  padding: 10px;
  font-size: 14px;
  position: absolute;
  top: 65px;
  right: -10px;
  box-shadow: 0 2px 20px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  strong {
    font-weight: 600;
  }
  small {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
  }
  @media screen and (max-width: 800px) {
    height: auto;
    opacity: 1;
    pointer-events: auto;
    position: initial;
    text-align: center;
    box-shadow: none;
  }
`;

const PubAccTitle = styled.h4`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

const PubAcc = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: ${(props) => (props.active ? "white" : "rgba(255,255,255,0.8)")};
  font-weight: ${(props) => (props.active ? "500" : "400")};
  cursor: pointer;
  transition: color 0.2s;
  svg {
    margin-left: 10px;
    margin-top: -4px;
  }
  :hover {
    color: ${(props) => (props.active ? "white" : "rgba(255,255,255,0.9)")};
  }
  @media screen and (max-width: 800px) {
    justify-content: center;
  }
`;

const Button = styled.button`
  margin-top: 15px;
  cursor: pointer;
  background: hsl(221, 92%, 85%);
  color: hsl(230, 85%, 45%);
  font-weight: 700;
  display: block;
  text-align: center;
  padding: 4px;
  width: 100%;
  border: none;
  border-radius: 2px;
  transition: background 0.2s;
  outline: none;
  :hover {
    background: hsl(221, 92%, 80%);
  }
`;

const HeaderDropdown = function (props) {
  let [open, setOpen] = useState(false);
  let [, setAuthState] = useGlobal("authState");
  HeaderDropdown.handleClickOutside = () => setOpen(false);

  const logout = async () => {
    await axios.post(
      apiRoot,
      {
        query: `
        mutation logout {
          logout
        }`,
      },
      { withCredentials: true }
    );
    setAuthState({ loading: false, authenticated: false });
    window.location = "https://my.music.ceo";
  };

  return (
    <UserDropdown>
      <UserDropdownBase onClick={() => setOpen(!open)}>
        <IconContainer>
          <Icon
            height="24px"
            width="24px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill="rgba(255,255,255,0.9)"
              d="M12 12a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
            />
            <path
              fill="rgba(255,255,255,0.9)"
              d="M21 20v-1a5 5 0 0 0-5-5H8a5 5 0 0 0-5 5v1c0 1.1.9 5 5 5h10a2 2 0 0 0 2-2z"
            />
          </Icon>
        </IconContainer>
        {props.authState.paymentProfile
          ? props.authState.paymentProfile.name
          : props.authState.user.name}
        <DownArrow
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 926.23699 717.1874250000001"
          version="1.1"
          x="0px"
          y="0px"
          width="12px"
          height="12px"
        >
          <g transform="translate(904.92214,-879.1482)">
            <path
              d="m -673.67664,1221.6502 -231.2455,-231.24803 55.6165,-55.627 c 30.5891,-30.59485 56.1806,-55.627 56.8701,-55.627 0.6894,0 79.8637,78.60862 175.9427,174.68583 l 174.6892,174.6858 174.6892,-174.6858 c 96.079,-96.07721 175.253196,-174.68583 175.942696,-174.68583 0.6895,0 26.281,25.03215 56.8701,55.627 l 55.6165,55.627 -231.245496,231.24803 c -127.185,127.1864 -231.5279,231.248 -231.873,231.248 -0.3451,0 -104.688,-104.0616 -231.873,-231.248 z"
              fill="rgba(255,255,255,0.5)"
            />
          </g>
        </DownArrow>
      </UserDropdownBase>
      <ExpandSection open={open}>
        <strong>{props.authState.user.name}</strong>
        <br />
        <small>{props.authState.user.email}</small>
        <br />
        {props.authState.paymentProfiles.length > 0 && (
          <PubAccTitle>Payment Profiles:</PubAccTitle>
        )}
        {props.authState.paymentProfile &&
          props.authState.paymentProfiles.map((i) => (
            <PubAcc
              key={i.id}
              active={i.id === props.authState.paymentProfile.id}
              onClick={() => {
                if (i.id !== props.authState.paymentProfile.id)
                  props.setPaymentProfile(i);
              }}
            >
              {i.name}
              {i.id === props.authState.paymentProfile.id && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="icon-check"
                  width="20"
                  height="20"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    fill="rgba(255,255,255,0.25)"
                  />
                  <path
                    fill="white"
                    d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                  />
                </svg>
              )}
            </PubAcc>
          ))}
        <Button onClick={logout}>Log out</Button>
      </ExpandSection>
    </UserDropdown>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => HeaderDropdown.handleClickOutside,
};

export default onClickOutside(HeaderDropdown, clickOutsideConfig);
