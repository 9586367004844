import React, { useState } from "react";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import InputMask from "react-input-mask";
import axios from "axios";
import apiRoot from "../../apiRoot";
import ButtonSelect from "../../components/ButtonSelect";
import Tooltip from "../../components/Tooltip";
import ItemSelect from "../../components/ItemSelect";
import states from "./states";
import encrypt from "./encrypt";
import getAuthState from "../../getAuthState";

const FormContainer = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 30px;
`;

const FormTitle = styled.h3`
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.9);
  margin-top: 0;
  font-size: 22px;
  margin-bottom: 5px;
  span {
    margin-left: 20px;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 600;
    small {
      font-size: 12px;
      font-weight: 400;
    }
  }
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const ExternalLink = styled.a`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin-bottom: 15px;
  display: inline-block;
`;

const InputLabel = styled.label`
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  margin-top: 10px;
  strong {
    margin-right: 5px;
    color: rgba(255, 255, 255, 0.8);
  }
`;

const Input = styled.input`
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: border-color 0.2s;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  padding: 4px 10px;
  width: 100%;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  background: transparent;
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.5);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5);
  }
  :focus {
    border-color: rgba(255, 255, 255, 0.2);
  }
`;

const Spacer = styled.div`
  margin-top: 5px;
`;

const PartLabel = styled.h3`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  margin-top: 30px;
  strong {
    color: white;
    background: rgba(255, 255, 255, 0.1);
    padding: 5px 10px;
    border-radius: 4px;
    margin-right: 10px;
    letter-spacing: 0.5px;
  }
`;

const Certification = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  br {
    line-height: 3;
  }
`;

const Button = styled.button`
  padding: 6px 25px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  line-height: 1;
  :hover {
    filter: brightness(110%);
  }
  span {
    display: inline-block;
    margin-top: 1px;
  }
  svg {
    margin-left: 10px;
    margin-right: -5px;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  margin-bottom: 10px;
`;

const Flex = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const InputGroup = styled.div`
  flex: 1;
`;

const HorizontalSpacer = styled.div`
  margin-right: 15px;
`;

const W9Form = () => {
  const [authState, setAuthState] = useGlobal("authState");
  const [, setCountries] = useGlobal("countries");

  let [name, setName] = useState("");
  let [businessName, setBusinessName] = useState("");
  let [taxClassification, setTaxClassification] = useState(null);
  let [llcClassification, setLlcClasification] = useState(null);
  let [otherClassification, setOtherClassification] = useState("");
  let [exemptPayeeCode, setExemptPayeeCode] = useState("");
  let [addressLine1, setAddressLine1] = useState("");
  let [city, setCity] = useState("");
  let [state, setState] = useState(null);
  let [zip, setZip] = useState("");
  let [tinType, setTinType] = useState(null);
  let [tin, setTin] = useState("");
  let [signature, setSignature] = useState("");
  let [error, setError] = useState("");
  let [working, setWorking] = useState(false);
  let [done, setDone] = useState(false);

  const validate = (text) => {
    const validCharacters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 &-,'/.";
    for (const i of text.split("")) {
      if (!validCharacters.split("").includes(i)) return false;
    }
    return true;
  };

  const submit = async () => {
    if (working) return;
    if (!name) return setError("You must state your name on line 1!");
    if (!taxClassification)
      return setError("You must list your tax classification!");
    if (taxClassification === "llc" && !llcClassification)
      return setError("You must list the tax classification of your LLC!");
    if (taxClassification === "other" && !otherClassification)
      return setError("You must note your other tax classification!");
    if (!addressLine1) return setError("You must include your address!");
    if (!city || !state || !zip || zip.length < 5)
      return setError("You must include your city, state, and ZIP code!");
    if (!tinType)
      return setError(
        "You must select the type of tax identification number to be included!"
      );
    if (!tin)
      return setError("You must include your tax identification number!");
    if (tin.replace(/-/g, "").length !== 9)
      return setError("Your TIN must be 9 digits long!");
    if (!signature)
      return setError("You must sign the certification statement!");
    if (
      !validate(name) ||
      !validate(businessName) ||
      !validate(addressLine1) ||
      !validate(city) ||
      !validate(otherClassification) ||
      !validate(exemptPayeeCode) ||
      !validate(signature)
    )
      return setError(
        "All inputs must only contain valid characters (alphanumeric, spaces, hyphens, apostrophes, commas, periods, ampersands). If your name or address includes a special character, use the closest English language alternative."
      );
    setWorking(true);
    // Actually submit the form
    const enc = await encrypt({ tinType, tin });
    let classification = taxClassification;
    if (classification === "other") classification = otherClassification;
    if (classification === "llc") classification = "llc-" + llcClassification;
    const res = await axios.post(
      apiRoot,
      {
        query: `
      mutation addTaxForm($id: Int!, $unenc: W9Input!, $enc: String!) {
        paymentProfile(id: $id) {
          addTaxInfo(type: W9, unencryptedW9: $unenc, encryptedInfo: $enc)
        }
      }`,
        variables: {
          id: authState.paymentProfile.id,
          unenc: {
            name,
            businessName,
            classification,
            addressLine1,
            city,
            state,
            zip,
            exemptPayeeCode,
            certificationName: signature,
          },
          enc,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
    if (res.data.errors) return setError(res.data.errors[0].message);
    await getAuthState(setAuthState, setCountries);
    setDone(true);
  };

  return (
    <div>
      {done && <Redirect to="/dashboard" />}
      <FormContainer>
        <FormTitle>
          FORM W-9{" "}
          <span>
            Request for Taxpayer Identification Number and Certification{" "}
            <small>(rev. October 2018)</small>
          </span>
        </FormTitle>
        <ExternalLink
          target="_blank"
          href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
        >
          Instructions for Form W-9 - Internal Revenue Service
        </ExternalLink>
        <InputLabel htmlFor="line1">
          <strong>Line 1 </strong>Name (as shown on your income tax return)
        </InputLabel>
        <Input
          id="line1"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <InputLabel htmlFor="line2">
          <strong>Line 2 </strong>Business name/disregarded entity name, if
          different from above
        </InputLabel>
        <Input
          id="line2"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
        />
        <InputLabel>
          <strong>Line 3 </strong>Select the federal tax classification of the
          person or entity listed in Line 1.
          <Tooltip>
            Select "Individual" if you are a sole proprietor or a single-member
            LLC owned by an individual and disregarded from its owner for
            federal tax purposes.
          </Tooltip>
        </InputLabel>
        <Spacer />
        <ButtonSelect
          options={[
            {
              id: "individual",
              text: "Individual",
            },
            { id: "c-corp", text: "C Corporation" },
            { id: "s-corp", text: "S Corporation" },
            { id: "partnership", text: "Partnership" },
            { id: "trust", text: "Trust/estate" },
            { id: "llc", text: "Limited liability company" },
            { id: "other", text: "Other" },
          ]}
          active={taxClassification}
          setActive={(choice) => {
            setTaxClassification(choice);
          }}
        />
        {taxClassification === "llc" && (
          <div>
            <InputLabel>
              What is the tax classification of the limited liability company?
              <Tooltip>
                If the LLC has a single member and is disregarded from its
                owner, select the tax classification of its owner above.
              </Tooltip>
            </InputLabel>
            <Spacer />
            <ButtonSelect
              options={[
                { id: "c-corp", text: "C Corporation" },
                { id: "s-corp", text: "S Corporation" },
                { id: "partnership", text: "Partnership" },
              ]}
              active={llcClassification}
              setActive={(choice) => setLlcClasification(choice)}
            />
          </div>
        )}{" "}
        {taxClassification === "other" && (
          <div>
            <InputLabel htmlFor="otherClassification">
              Enter the other tax classification below.
            </InputLabel>
            <Input
              id="otherClassification"
              value={otherClassification}
              onChange={(e) => setOtherClassification(e.target.value)}
            />
          </div>
        )}
        {taxClassification && taxClassification !== "individual" && (
          <div>
            <InputLabel htmlFor="line4">
              <strong>Line 4 </strong>Exempt payee code (if any - codes apply to
              certain entities, not individuals)
            </InputLabel>
            <Input
              id="line4"
              value={exemptPayeeCode}
              onChange={(e) => setExemptPayeeCode(e.target.value)}
            />
          </div>
        )}
        <InputLabel htmlFor="line5">
          <strong>Line 5 </strong>Address (number, street, and apt. or suite
          no.)
        </InputLabel>
        <Input
          id="line5"
          value={addressLine1}
          onChange={(e) => setAddressLine1(e.target.value)}
        />
        <Flex>
          <InputGroup>
            <InputLabel htmlFor="line6">
              <strong>Line 6 </strong>City
            </InputLabel>
            <Input
              id="line6"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </InputGroup>
          <HorizontalSpacer />
          <InputGroup>
            <InputLabel>State</InputLabel>
            <ItemSelect
              items={states}
              value={state}
              onChange={(i) => setState(i)}
              disallowNone
            />
          </InputGroup>
          <HorizontalSpacer />
          <InputGroup>
            <InputLabel htmlFor="zip">ZIP Code</InputLabel>
            <InputMask
              mask="99999"
              maskChar=""
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            >
              {(inputProps) => <Input id="zip" {...inputProps}></Input>}
            </InputMask>
          </InputGroup>
        </Flex>
        <PartLabel>
          <strong>PART I</strong>Taxpayer Identification Number (TIN)
        </PartLabel>
        <InputLabel>
          Select the type of TIN that applies to you.
          <Tooltip>
            For individuals, including disregarded single-member LLCs owned by
            individuals, this is generally your SSN, though sole proprietors can
            supply an EIN. Entities should supply an EIN.
          </Tooltip>
        </InputLabel>
        <Spacer />
        <ButtonSelect
          options={[
            { id: "ssn", text: "Social Security Number (SSN)" },
            { id: "ein", text: "Employer Identification Number (EIN)" },
          ]}
          active={tinType}
          setActive={(type) => setTinType(type)}
        />
        {tinType === "ssn" && (
          <div>
            <InputLabel htmlFor="ssn">Social Security Number</InputLabel>
            <InputMask
              mask="999-99-9999"
              maskChar=""
              value={tin}
              onChange={(e) => setTin(e.target.value)}
            >
              {(inputProps) => <Input id="ssn" {...inputProps}></Input>}
            </InputMask>
          </div>
        )}
        {tinType === "ein" && (
          <div>
            <InputLabel htmlFor="ein">
              Employer Identification Number
            </InputLabel>
            <InputMask
              mask="99-9999999"
              maskChar=""
              value={tin}
              onChange={(e) => setTin(e.target.value)}
            >
              {(inputProps) => <Input id="ein" {...inputProps}></Input>}
            </InputMask>
          </div>
        )}
        <PartLabel>
          <strong>PART II</strong>Certification
        </PartLabel>
        <Certification>
          Under penalties of perjury, I certify that:
          <Spacer />
          1. The number shown on this form is my correct taxpayer identification
          number
          <Spacer />
          2. I am not subject to backup withholding because: (a) I am exempt
          from backup withholding, or (b) I have not been notified by the
          Internal Revenue Service (IRS) that I am subject to backup withholding
          as a result of a failure to report all interest or dividends, or (c)
          the IRS has notified me that I am no longer subject to backup
          withholding
          <Spacer />
          3. I am a U.S. citizen or other U.S. person
        </Certification>
        <InputLabel htmlFor="certification">
          <strong>Signature</strong> (type your name here - this constitutes a
          legally binding electronic signature)
        </InputLabel>
        <Input
          autoComplete="none"
          id="certification"
          value={signature}
          onChange={(e) => setSignature(e.target.value)}
        />
      </FormContainer>
      <br />
      {error && (
        <ErrorMsg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="hsl(5, 100%, 70%)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          {error}
        </ErrorMsg>
      )}
      <Button onClick={submit}>
        <span>Submit tax form</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          height="24"
          width="24"
        >
          <path
            fill="white"
            d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
          />
        </svg>
      </Button>
      <InputLabel>
        Your personal information is secure, and is always transmitted and
        stored in an encrypted form.
      </InputLabel>
    </div>
  );
};

export default W9Form;
