import React from "react";
import styled from "styled-components";

const BoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Box = styled.div`
  border-radius: 3px;
  ${(props) => !props.active && "border: 2px solid rgba(255,255,255,0.2);"}
  ${(props) =>
    props.active &&
    "background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));"}
  width: ${(props) => (props.small ? "18px" : "24px")};
  height: ${(props) => (props.small ? "18px" : "24px")};
  min-width: ${(props) => (props.small ? "18px" : "24px")};
  min-height: ${(props) => (props.small ? "18px" : "24px")};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const Text = styled.span`
  color: ${(props) =>
    props.small ? "rgba(255, 255, 255, 0.6)" : "rgba(255, 255, 255, 0.8)"};
  font-weight: 500;
  margin-left: ${(props) => (props.small ? "10px" : "15px")};
  font-size: ${(props) => (props.small ? "12px" : "14px")};
  cursor: default;
`;

const ShadowSvg = styled.svg`
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.25));
`;

const Checkbox = (props) => {
  return (
    <BoxContainer>
      <Box
        small={props.small}
        active={props.active}
        onClick={() => props.setActive(!props.active)}
      >
        {props.active && (
          <ShadowSvg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={props.small ? "20" : "24"}
            height={props.small ? "20" : "24"}
          >
            <path
              fill="white"
              d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
            />
          </ShadowSvg>
        )}
      </Box>
      <Text small={props.small} onClick={() => props.setActive(!props.active)}>
        {props.children}
      </Text>
    </BoxContainer>
  );
};

export default Checkbox;
