import React from "react";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
`;

const Button = styled.div`
  border: 1px solid
    ${(props) =>
      props.active ? "hsl(180, 95%, 40%)" : "rgba(255,255,255,0.15)"};
  background: ${(props) =>
    props.active ? "hsl(180, 85%, 13%)" : "rgba(255,255,255,0.05)"};
  color: ${(props) =>
    props.active ? "hsl(185, 95%, 65%)" : "rgba(255,255,255,0.8)"};
  padding: 5px 20px;
  font-weight: 500;
  font-size: 14px;
  margin-right: ${(props) => (props.fullWidth ? "0" : "10px")};
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  ${(props) => props.fullWidth && "width: 100%;"}
`;

const ButtonSelect = (props) => {
  return (
    <ButtonContainer>
      {props.options.map((option) => (
        <Button
          active={option.id === props.active}
          key={option.id}
          onClick={() => props.setActive(option.id)}
          fullWidth={props.fullWidth}
        >
          {option.text}
        </Button>
      ))}
    </ButtonContainer>
  );
};

export default ButtonSelect;
